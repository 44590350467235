import { loader } from 'graphql.macro';

//types
import { TextFieldState } from '../../../../hooks/useTextFieldState';
import { BoolFieldState } from '../../../../hooks/useBoolFieldState';
import {
  Maybe,
  AppointmentMedium,
  CompanyAppointmentType,
} from '../../../../generated/graphql';

//queries

//helpers
import { mapFormDataToCustomFormSchema } from '../helpers/customFormMappers';
import { Roles, RoleToString } from '../../../../helpers';
import Flags from '../../../../constants/flags';

//hooks
import useSnackbar from '../../../../hooks/useSnackbar';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';
import {
  useRemoveAppointmentTypeForEmployeeMutation,
  useSetAppointmentTypeEmployeesMutation,
  useUpdateAppointmentTypeMutation,
} from '../../../../generated/graphql';
import useFeatureFlagWrapper from '../../../../hooks/useFeatureFlagWrapper';

const QUERY_APPOINTMENT_TYPE = loader(
  '../../../../gql/appointmentTypes/AppointmentType.graphql'
);

export interface UpdateAppointmentTypePayload {
  appointmentTypeId: string;
  displayNameFormState: TextFieldState;
  durationFormState: TextFieldState;
  intervalFormState: TextFieldState;
  legacyIsInitialEvalFormState: BoolFieldState;
  canChooseProviderFormState: BoolFieldState;
  shouldRequireManualInsuranceFormState: BoolFieldState;
  shouldShowFinalCheckboxFormState: BoolFieldState;
}

export interface UpdateAppointmentQuestionsPayload {
  appointmentTypeId: string;
  formQuestions: any;
}

export interface ToggleArchiveAppointmentTypePayload {
  appointmentTypeId: string;
  isActive: boolean;
}

export interface SetAppointmentTypeProvidersPayload {
  appointmentTypeId: string;
  providerIds: string[];
  existingProviderIds: string[];
}

export interface RemoveProviderFromAppointmentTypePayload {
  employeeId: string;
  appointmentTypeId: string;
}

export interface MapAppointmentTypeDetailsPayload {
  appointmentType: Maybe<Partial<CompanyAppointmentType>> | undefined;
  loading: boolean;
}

export interface MappedAppointmentTypeProvider {
  id: string;
  name: string;
  roleToDisplay: string;
  roleForPermissions: string;
  isIntegrated: boolean;
}

interface MappedAppointmentTypeFacility {
  id: string;
  name: string;
  address: string;
  city: string;
  state: string;
}

export interface MappedAppointmentTypeDetail {
  loading: boolean;
  id: string;
  companyId: string;
  displayName: string;
  duration: string;
  interval: string;
  medium: string;
  canChooseProvider: boolean;
  shouldRequireManualInsurance?: boolean;
  shouldShowFinalCheckbox?: boolean;
  providers: MappedAppointmentTypeProvider[];
  facilities: MappedAppointmentTypeFacility[];
  customFormTemplate?: any;
  customFormUISchemaTemplate?: any;
  isActive?: boolean;
  rawAppointmentType?: Partial<CompanyAppointmentType>;
  legacyIsInitialEval: string;
}

const useAppointmentTypeDetailOperations = () => {
  const featureFlagWrapper = useFeatureFlagWrapper();
  const snackbar = useSnackbar();
  const { employeeSingularAlias } = useRemoteConfigAliases();
  const [updateAppointmentType] = useUpdateAppointmentTypeMutation();
  const [setProviders] = useSetAppointmentTypeEmployeesMutation();
  const [removeProvider] = useRemoveAppointmentTypeForEmployeeMutation();

  const mapAppointmentTypeDetails = (
    payload: MapAppointmentTypeDetailsPayload
  ): MappedAppointmentTypeDetail => {
    const { appointmentType, loading } = payload;

    if (!loading && !appointmentType) {
      snackbar?.setErrorMessage(
        'This appointment type could not be accessed. Please check the url bar, or go back and try again.'
      );
    }

    const apptMedium =
      appointmentType?.medium === AppointmentMedium.InClinic
        ? 'In-Person'
        : 'Telehealth';

    const handleLoading = (fieldValue: string) =>
      !!loading ? 'Loading...' : fieldValue;

    return {
      loading: !!loading,
      id: appointmentType?.id ?? '',
      companyId: appointmentType?.companyId ?? '',
      displayName: handleLoading(appointmentType?.displayName ?? 'N/A'),
      duration: handleLoading(
        appointmentType?.duration
          ? `${appointmentType.duration} minutes`
          : 'N/A'
      ),
      interval: handleLoading(
        appointmentType?.interval
          ? `${appointmentType.interval} minutes`
          : 'N/A'
      ),
      medium: handleLoading(appointmentType?.medium ? apptMedium : 'N/A'),
      canChooseProvider: !appointmentType?.shouldHideProviderOption,
      shouldRequireManualInsurance: !!appointmentType?.shouldRequireManualInsurance,
      shouldShowFinalCheckbox: !appointmentType?.shouldHideFinalCheckbox,
      providers:
        appointmentType?.employees?.map((provider) => {
          const rawName = `${provider?.firstName ?? ''} ${provider?.lastName ??
            ''}`;
          const name = rawName.trim().length ? rawName : '-';
          return {
            id: provider?.id ?? '',
            name,
            roleToDisplay: provider?.role
              ? RoleToString(Roles[provider.role])
              : '-',
            roleForPermissions: provider?.role ?? 'initial',
            isIntegrated: !!provider?.isIntegrated,
          };
        }) ?? [],
      facilities:
        appointmentType?.clinics?.map((facility) => {
          const rawAddress = `${facility?.location?.address ?? ''}${facility?.location?.address2
            ? `, ${facility?.location?.address2}`
            : ''
            }`;
          const addressOrServiceArea = facility?.location?.isServiceArea
            ? facility?.location?.serviceAreaName ?? ''
            : rawAddress;
          const address = addressOrServiceArea.trim().length
            ? addressOrServiceArea
            : '-';
          return {
            id: facility?.id ?? '',
            name: facility?.clinicName ?? '-',
            address,
            city: facility?.location?.city ?? '-',
            state: facility?.location?.state ?? '-',
          };
        }) ?? [],
      customFormTemplate: appointmentType?.customFormTemplate,
      customFormUISchemaTemplate: appointmentType?.customFormUISchemaTemplate,
      isActive: appointmentType?.isActive,
      legacyIsInitialEval:
        appointmentType?.legacyIsInitialEval === true
          ? 'Initial Evaluation'
          : appointmentType?.legacyIsInitialEval === false
            ? 'Follow Up'
            : 'None',
    };
  };

  const updateAppointmentTypeDetails = async (
    payload: UpdateAppointmentTypePayload
  ) => {
    const {
      appointmentTypeId,
      displayNameFormState,
      durationFormState,
      intervalFormState,
      canChooseProviderFormState,
      legacyIsInitialEvalFormState,
      shouldRequireManualInsuranceFormState,
      shouldShowFinalCheckboxFormState,
    } = payload;

    if (!appointmentTypeId) {
      throw new Error(
        'This appointment type could not be accessed. Please refresh and try again.'
      );
    } else if (!displayNameFormState.valid) {
      throw new Error('Please enter a name for this appointment type.');
    } else if (!durationFormState.valid) {
      throw new Error(
        'Please select a valid duration for this appointment type.'
      );
    } else if (!intervalFormState.valid) {
      throw new Error(
        'Please select a valid interval for this appointment type.'
      );
    }
    await updateAppointmentType({
      variables: {
        input: {
          appointmentTypeId,
          displayName: displayNameFormState.value,
          duration: parseInt(durationFormState.value),
          interval: parseInt(intervalFormState.value),
          shouldHideProviderOption: !canChooseProviderFormState.value,
          legacyIsInitialEval: legacyIsInitialEvalFormState.value,
          shouldRequireManualInsurance: !!shouldRequireManualInsuranceFormState.value,
          shouldHideFinalCheckbox: !shouldShowFinalCheckboxFormState.value,
        },
      },
    });
  };

  const updateAppointmentTypeQuestions = async (
    payload: UpdateAppointmentQuestionsPayload
  ) => {
    const { appointmentTypeId, formQuestions } = payload;

    if (!appointmentTypeId) {
      throw new Error(
        'This appointment type could not be accessed. Please refresh and try again.'
      );
    } else if (!formQuestions) {
      throw new Error(
        'Something went wrong processing your custom form. Please refresh and try again.'
      );
    }

    // runs in case of RJSF schema.
    if (!formQuestions.hasOwnProperty('schemaVersion')) {
      const {
        customFormTemplate,
        customFormUISchemaTemplate,
      } = mapFormDataToCustomFormSchema(formQuestions);

      await updateAppointmentType({
        variables: {
          input: {
            appointmentTypeId,
            customFormTemplate,
            customFormUISchemaTemplate,
          },
        },
      });
    }
    // runs in case of custom schema.
    else {
      await updateAppointmentType({
        variables: {
          input: {
            appointmentTypeId,
            customFormTemplate: formQuestions,
          },
        },
      });
    }
  };

  const toggleArchiveAppointmentType = async (
    payload: ToggleArchiveAppointmentTypePayload
  ) => {
    const { appointmentTypeId, isActive } = payload;

    if (!appointmentTypeId) {
      throw new Error(
        'This appointment type could not be accessed. Please refresh and try again.'
      );
    }
    await updateAppointmentType({
      variables: {
        input: {
          appointmentTypeId,
          isActive,
        },
      },
    });
  };

  const setAppointmentTypeProviders = async (
    payload: SetAppointmentTypeProvidersPayload
  ) => {
    let updatedBackend = false;
    const { appointmentTypeId, providerIds, existingProviderIds } = payload;

    if (!appointmentTypeId) {
      throw new Error(
        'This appointment type could not be accessed. Please refresh and try again.'
      );
    }

    if (
      providerIds.length !== existingProviderIds.length ||
      providerIds.some((id) => !existingProviderIds.includes(id)) ||
      existingProviderIds.some((id) => !providerIds.includes(id))
    ) {
      await setProviders({
        variables: {
          input: {
            appointmentTypeId,
            employeeIds: providerIds,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: QUERY_APPOINTMENT_TYPE,
            variables: {
              id: appointmentTypeId,
            },
          },
        ],
      });
      updatedBackend = true;
    }
    return updatedBackend;
  };

  const removeProviderFromAppointmentType = async (
    payload: RemoveProviderFromAppointmentTypePayload
  ) => {
    const { employeeId, appointmentTypeId } = payload;

    if (!employeeId) {
      throw new Error(
        `This ${employeeSingularAlias.toLowerCase()} could not be accessed. Please refresh and try again.`
      );
    } else if (!appointmentTypeId) {
      throw new Error(
        'This appointment type could not be accessed. Please refresh and try again.'
      );
    }

    await removeProvider({
      variables: {
        input: {
          employeeId,
          appointmentTypeId,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: QUERY_APPOINTMENT_TYPE,
          variables: {
            id: appointmentTypeId,
          },
        },
      ],
    });
  };

  return {
    commands: {
      updateAppointmentTypeDetails,
      updateAppointmentTypeQuestions,
      removeProviderFromAppointmentType,
      setAppointmentTypeProviders,
      toggleArchiveAppointmentType,
    },
    queries: { mapAppointmentTypeDetails },
  };
};

export default useAppointmentTypeDetailOperations;