import React from 'react';

//types
import { MappedAppointmentTypeDetail } from '../hooks/useAppointmentTypeDetailOperations';

//components
import { Animation, Button, Header } from '@betterpt/better-components';
import { GridCard } from '../../../Shared/GridCard';
import DisplayQuestionNew from './DisplayQuestionNew';
import NoCustomQuestions from './NoCustomQuestions';
import Can from '../../../Can';

import { Roles } from '../../../../helpers';
import { useMeQuery } from '../../../../generated/graphql';
import _ from 'lodash';
import { Question } from './CustomLogicComponents/CustomTypes';

//styles
const headerStyle = { padding: '15px 25px 10px 50px' };

interface Props {
    openEditQuestions: () => void;
    mappedAppointmentType: MappedAppointmentTypeDetail;
}

const CustomQuestionsNew: React.FC<React.PropsWithChildren<Props>> = ({
    openEditQuestions,
    mappedAppointmentType,
}) => {
    const questions = function () {
        let parentQuestions = mappedAppointmentType.customFormTemplate?.questions.filter(item => item.level === 0);
        if (mappedAppointmentType.customFormTemplate?.questions.length > 0) {
            let childQuestions = _(mappedAppointmentType.customFormTemplate?.questions).groupBy(item => item.parentId).value();
            let sortedQuestions: Question[] = [];
            parentQuestions.map((item: any) => {
                if (!childQuestions[item.id]) sortedQuestions.push(item);
                else if (childQuestions[item.id]) {
                    let otherArr = [item, ...childQuestions[item.id]];
                    sortedQuestions.push(...otherArr)
                }
            });
            return sortedQuestions;
        }
        return [];
    }();

    const meQuery = useMeQuery();

    return (
        <GridCard fullWidth>
            {mappedAppointmentType.loading ? (
                <Animation type='providerAppLoader' />
            ) : (
                <>
                    <Header style={headerStyle} underline>
                        <h2 className='H3'>Qualifying Questions</h2>
                        {questions.length > 0 && (
                            <Can
                                role={Roles[meQuery.data?.me?.role ?? 'initial']}
                                perform='appointmentType:edit'
                                yes={() => (
                                    <Button color='gray' size='small' onClick={openEditQuestions}>
                                        EDIT
                                    </Button>
                                )}
                            />
                        )}
                    </Header>
                    {questions.length > 0 ? (
                        questions.map((question: any, index: number) => (
                            <DisplayQuestionNew
                                key={question.id}
                                question={question}
                                index={index}
                            />
                        ))
                    ) : (
                        <NoCustomQuestions openEditQuestions={openEditQuestions} />
                    )}
                </>
            )}
        </GridCard>
    );
};

export default CustomQuestionsNew;