import React from 'react';

//types
import { TextFieldState } from '../../../../../../hooks/useTextFieldState';

// helpers
import { possessiveName } from '../../../../../../helpers';

//hooks
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';
import useSnackbar from '../../../../../../hooks/useSnackbar';

// components
import Dialog from '@material-ui/core/Dialog';
import { Button, CloseButton } from '@betterpt/better-components';
import ThemedTextField from '../../../../../Shared/ThemedTextField';
import SlideTransition from '../../../../../Shared/SlideTransition';

//styles
import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';
const DialogBody = styled.div`
  position: relative;
  padding: 50px 58px 50px 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  position: relative;
`;

const DialogHeader = styled.h1`
  font-size: 20px;
  font-weight: normal;
  margin: 0;
  margin-bottom: 38px;
  width: 100%;
  text-align: left;
`;

const dialogButtonStyle = (color?: string) => ({
  backgroundColor: color || colorValues.ibizateal,
  fontWeight: 'bold' as 'bold',
  marginTop: '20px',
  height: 48,
});

interface Props {
  header: string;
  CTA: string;
  open: boolean;
  onClose: () => void;
  handleButton: () => void;
  phone: Partial<TextFieldState>;
  name: TextFieldState;
}

const AddChatParticipantDialog = ({
  open,
  onClose,
  handleButton,
  phone,
  name,
  header,
  CTA,
}: Props) => {
  const snackbar = useSnackbar();
  const {
    loading,
    patientsSingularAlias,
    primaryColor,
  } = useRemoteConfigAliases();

  return (
    <Dialog
      TransitionComponent={SlideTransition}
      open={open}
      onClose={onClose}
      maxWidth='sm'
      PaperProps={{
        style: {
          width: 600,
          maxWidth: 600,
        },
      }}
    >
      <DialogBody>
        <DialogHeader>{header}</DialogHeader>
        <ThemedTextField
          fullWidth
          label='Name'
          value={name.value}
          placeholder={
            loading
              ? ''
              : `Type your ${possessiveName(
                  patientsSingularAlias.toLowerCase()
                )} first and last name here ${
                  CTA.includes('EDIT') ? '' : '(Optional)'
                }`
          }
          onChange={(e) => name.updateValue(e.target.value)}
        />
        <ThemedTextField
          fullWidth
          label='Phone'
          placeholder={
            loading
              ? ''
              : `Enter ${patientsSingularAlias.toLowerCase()} phone (Required)`
          }
          value={phone.value}
          onChange={(e) => phone.updateValue?.(e.target.value)}
          disabled={CTA.includes('NAME')}
          error={snackbar?.errorMessage === 'Mobile phone number is required'}
          helperText={
            snackbar?.errorMessage === 'Mobile phone number is required'
              ? 'Required'
              : ''
          }
        />

        <Button
          fullWidth
          size='large'
          color='transparent'
          style={dialogButtonStyle(primaryColor)}
          onClick={handleButton}
        >
          {CTA}
        </Button>
        <CloseButton onClose={onClose} />
      </DialogBody>
    </Dialog>
  );
};

export default AddChatParticipantDialog;
