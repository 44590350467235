import React from 'react';

//types
import { Message } from '../../../../../../../generated/graphql';

//helpers
import { format } from 'date-fns-tz';

//hooks
import { useConversationQuery } from '../../../../../../../generated/graphql';

//compoonents
import Signature from './Signature';
import ReadOrUnreadMarker from './ReadOrUnreadMarker';

//styles
import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 23px 0 0 0;
  align-items: flex-end;
  position: relative;
`;

const MessageText = styled.div<{ showExtraRoom: boolean }>`
  background-color: ${colorValues.dust};
  width: 514px;
  margin-left: 35px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-bottom: ${({ showExtraRoom }) => (showExtraRoom ? '20px' : '8px')};
  position: relative;
  p {
    color: ${colorValues.betterptblack};
    margin: 14px 15px;
  }
`;

interface Props {
  message: Message;
  renderSignature: boolean;
  showUnread: boolean;
  readButNotRepliedTo: boolean;
  handleMarkAsRead: ({ messageId }: { messageId: string }) => Promise<void>;
  markingIdAsRead?: string;
}

const PatientMessageBubble = ({
  message,
  renderSignature,
  showUnread,
  readButNotRepliedTo,
  handleMarkAsRead,
  markingIdAsRead,
}: Props) => {
  const conversationQuery = useConversationQuery({
    variables: {
      id: message?.conversationId!,
    },
    skip: !message?.conversationId,
    fetchPolicy: 'cache-first',
  });

  const date = format(
    new Date(message.timestamp ?? new Date()),
    'M/d/yy h:mm aa'
  );
  const text = date;
  const showExtraRoom = renderSignature || showUnread;

  return (
    <Wrapper>
      <>
        {conversationQuery.loading ? null : (
          <MessageText showExtraRoom={showExtraRoom}>
            {message.text.split('\n').map((t, i) => (
              <p key={i}>{t}</p>
            ))}
            <Signature renderSignature={renderSignature} text={text} patient />
            <ReadOrUnreadMarker
              message={message}
              showUnread={showUnread}
              handleMarkAsRead={handleMarkAsRead}
              readButNotRepliedTo={readButNotRepliedTo}
              markingIdAsRead={markingIdAsRead}
            />
          </MessageText>
        )}
      </>
    </Wrapper>
  );
};

export default PatientMessageBubble;
