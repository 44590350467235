import React from 'react';

//types
import {
  MappedAppointmentTypeDetail,
  ToggleArchiveAppointmentTypePayload,
  UpdateAppointmentTypePayload,
} from '../hooks/useAppointmentTypeDetailOperations';
import { CompanyAppointmentType, Maybe } from '../../../../generated/graphql';

//helpers
import {
  normalizeOnlyNumbers,
  validateTimePeriod,
  Roles,
} from '../../../../helpers';
import Flags from '../../../../constants/flags';

//hooks
import { useParams } from 'react-router-dom';
import { useMeQuery } from '../../../../generated/graphql';
import useSnackbar from '../../../../hooks/useSnackbar';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';
import useTextFieldState from '../../../../hooks/useTextFieldState';
import useBoolFieldState from '../../../../hooks/useBoolFieldState';
import useFeatureFlagWrapper from '../../../../hooks/useFeatureFlagWrapper';
import useHandleError from '../../../../hooks/useHandleError';

//components
import {
  Animation,
  Card,
  EditSaveButton,
  Header,
  Switch,
  TextField,
} from '@betterpt/better-components';
import EditAppointmentTypeDialog from './EditAppointmentTypeDialog';
import AreYouSureDialog from './AreYouSureDialog';
import Can from '../../../Can';
import FeatureFlagCheck from '../../../Shared/FeatureFlagCheck';

//styles
import {
  DetailCardBody,
  CardTitle,
  Loader,
  Row,
  detailCardHeaderStyle,
  cardStyle,
} from '../../../Shared/AppointmentTypes/AppointmentTypes.style';
import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

const ArchivedIcon = styled.div`
  color: ${colorValues.emptiness};
  background-color: ${colorValues.messyketchup};
  font-weight: bold;
  font-size: 12px;
  border-radius: 5px;
  padding: 3px 10px;
  margin-left: 10px;
`;
const rowStyle = { marginBottom: '20px' };

interface Props {
  loading: boolean;
  appointmentType: Maybe<Partial<CompanyAppointmentType>> | undefined;
  mappedAppointmentType: MappedAppointmentTypeDetail;
  updateAppointmentTypeDetails: (
    payload: UpdateAppointmentTypePayload
  ) => Promise<void>;
  toggleArchiveAppointmentType: (
    payload: ToggleArchiveAppointmentTypePayload
  ) => Promise<void>;
}

const AppointmentTypeCard = ({
  loading,
  appointmentType,
  mappedAppointmentType,
  updateAppointmentTypeDetails,
  toggleArchiveAppointmentType,
}: Props) => {
  const featureFlagWrapper = useFeatureFlagWrapper();
  const { appointmentTypeId } = useParams<{ appointmentTypeId: string }>();
  const snackbar = useSnackbar();
  const handleError = useHandleError();
  const meQuery = useMeQuery();
  const {
    patientsSingularAlias,
    employeeSingularAlias,
  } = useRemoteConfigAliases();

  const [editDialogOpen, updateEditDialogOpen] = React.useState(false);
  const [areYouSureOpen, updateAreYouSureOpen] = React.useState(false);
  const [saving, updateSaving] = React.useState(false);
  const displayNameFormState = useTextFieldState({ required: true });
  const legacyIsInitialEvalFormState = useBoolFieldState({
    initialValue: null,
  });
  const durationFormState = useTextFieldState({
    required: true,
    validation: validateTimePeriod('duration'),
    normalizer: normalizeOnlyNumbers,
  });
  const intervalFormState = useTextFieldState({
    required: true,
    validation: validateTimePeriod('interval'),
    normalizer: normalizeOnlyNumbers,
  });
  const canChooseProviderFormState = useBoolFieldState({
    initialValue: mappedAppointmentType.canChooseProvider,
  });

  const shouldRequireManualInsuranceFormState = useBoolFieldState({
    initialValue: mappedAppointmentType.shouldRequireManualInsurance,
  });
  const shouldShowFinalCheckboxFormState = useBoolFieldState({
    initialValue: mappedAppointmentType.shouldShowFinalCheckbox,
  });

  const setStateValues = React.useCallback(
    (appointmentType: CompanyAppointmentType) => {
      displayNameFormState.updateValue(appointmentType?.displayName ?? '');
      durationFormState.updateValue(
        appointmentType?.duration?.toString() ?? ''
      );
      intervalFormState.updateValue(
        appointmentType?.interval?.toString() ?? ''
      );
      legacyIsInitialEvalFormState.updateValue(
        appointmentType.legacyIsInitialEval
      );
      shouldRequireManualInsuranceFormState.updateValue(
        appointmentType.shouldRequireManualInsurance
      );
      canChooseProviderFormState.updateValue(
        !appointmentType?.shouldHideProviderOption
      );
      shouldShowFinalCheckboxFormState.updateValue(
        !appointmentType?.shouldHideFinalCheckbox
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  React.useEffect(() => {
    if (!loading && appointmentType) {
      setStateValues(appointmentType as CompanyAppointmentType);
    }
  }, [appointmentType, loading, setStateValues]);

  React.useEffect(() => {
    if (snackbar?.errorMessage) {
      updateAreYouSureOpen(false);
    }
  }, [snackbar]);

  const userChangedApptType =
    mappedAppointmentType?.displayName !== displayNameFormState.value ||
    appointmentType?.duration !== parseInt(durationFormState.value, 10) ||
    appointmentType?.interval !== parseInt(intervalFormState.value, 10) ||
    appointmentType?.legacyIsInitialEval !==
      legacyIsInitialEvalFormState.value ||
    appointmentType?.shouldHideProviderOption ===
      canChooseProviderFormState.value ||
    appointmentType?.shouldRequireManualInsurance !==
      shouldRequireManualInsuranceFormState.value ||
    !appointmentType?.shouldHideFinalCheckbox !==
      shouldShowFinalCheckboxFormState.value;

  const handleSubmit = async () => {
    updateSaving(true);
    try {
      await updateAppointmentTypeDetails({
        appointmentTypeId,
        displayNameFormState,
        durationFormState,
        intervalFormState,
        canChooseProviderFormState,
        legacyIsInitialEvalFormState,
        shouldRequireManualInsuranceFormState,
        shouldShowFinalCheckboxFormState,
      });
      snackbar?.setSuccessMessage('Appointment type details saved');
      updateSaving(false);
      updateAreYouSureOpen(false);
      updateEditDialogOpen(false);
    } catch (e) {
      updateSaving(false);
      handleError(e);
    }
  };

  const onClickSave = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault?.();
    if (userChangedApptType) {
      updateAreYouSureOpen(true);
    } else {
      updateEditDialogOpen(false);
      areYouSureOpen && updateAreYouSureOpen(false);
    }
  };

  const onEditApptDialogClose = () => {
    updateEditDialogOpen(false);
    setStateValues(appointmentType as CompanyAppointmentType);
  };

  const renderArchivedIcon = () => {
    switch (mappedAppointmentType.isActive) {
      case false:
        return <ArchivedIcon>ARCHIVED</ArchivedIcon>;
      default:
        return null;
    }
  };

  return (
    <>
      <EditAppointmentTypeDialog
        open={editDialogOpen}
        onClose={onEditApptDialogClose}
        handleSave={onClickSave}
        mappedAppointmentType={mappedAppointmentType}
        displayNameFormState={displayNameFormState}
        legacyIsInitialEvalFormState={legacyIsInitialEvalFormState}
        durationFormState={durationFormState}
        intervalFormState={intervalFormState}
        canChooseProviderFormState={canChooseProviderFormState}
        toggleArchiveAppointmentType={toggleArchiveAppointmentType}
        shouldRequireManualInsuranceFormState={
          shouldRequireManualInsuranceFormState
        }
        shouldShowFinalCheckboxFormState={shouldShowFinalCheckboxFormState}
      />
      <AreYouSureDialog
        saving={saving}
        open={areYouSureOpen}
        onClose={() => updateAreYouSureOpen(false)}
        onContinue={handleSubmit}
      />
      <Card fullWidth style={cardStyle()}>
        <Header underline style={detailCardHeaderStyle}>
          {!loading && (
            <CardTitle>
              {mappedAppointmentType.displayName}
              {renderArchivedIcon()}
            </CardTitle>
          )}
          {!loading && (
            <Can
              role={Roles[meQuery.data?.me?.role ?? 'initial']}
              perform='appointmentType:edit'
              yes={() => (
                <EditSaveButton
                  editModeActive={false}
                  onClickEdit={() => updateEditDialogOpen(true)}
                  onClickSave={() => undefined}
                  loading={saving}
                  data-cy='edit-appointment-type-button'
                />
              )}
            />
          )}
        </Header>

        {loading ? (
          <Loader>
            <Animation type='providerAppLoader' />
          </Loader>
        ) : (
          <DetailCardBody>
            <Row style={rowStyle}>
              <TextField
                fullWidth
                disabled
                id='apptName'
                label='Name'
                InputLabelProps={{ shrink: true }}
                value={mappedAppointmentType.displayName}
                style={{ marginRight: '20px' }}
                data-cy='appointment-type-name'
              />
              <TextField
                fullWidth
                disabled
                id='apptMedium'
                label='Medium'
                value={mappedAppointmentType.medium}
                data-cy='appointment-type-medium'
              />
            </Row>
            <Row style={rowStyle}>
              <TextField
                fullWidth
                disabled
                id='apptDuration'
                label='Duration'
                InputLabelProps={{ shrink: true }}
                value={mappedAppointmentType.duration}
                style={{ marginRight: '20px' }}
                data-cy='appointment-type-duration'
              />
              <TextField
                fullWidth
                disabled
                id='apptInterval'
                label='Time Interval'
                InputLabelProps={{ shrink: true }}
                value={mappedAppointmentType.interval}
                data-cy='appointment-type-interval'
              />
            </Row>
            <Row style={rowStyle}>
              <TextField
                fullWidth
                disabled
                label={`${employeeSingularAlias} Select`}
                id='chooseProvider'
                style={
                  meQuery.data?.me?.company?.hasIntegration
                    ? { marginRight: '20px' }
                    : {}
                }
                value={`${patientsSingularAlias} ability to choose a specific ${employeeSingularAlias.toLowerCase()} is turned ${
                  canChooseProviderFormState.value ? 'ON' : 'OFF'
                }`}
                InputProps={{
                  endAdornment: (
                    <Switch
                      disabled
                      name='chooseProviderSwitch'
                      value={canChooseProviderFormState.value}
                      checked={!!canChooseProviderFormState.value}
                    />
                  ),
                }}
              />
              {meQuery.data?.me?.company?.hasIntegration && (
                <TextField
                  fullWidth
                  disabled
                  id='legacyIsInitialEval'
                  label='Optional Legacy Appointment Type (for bidirectional integrations only)'
                  InputLabelProps={{ shrink: true }}
                  value={mappedAppointmentType.legacyIsInitialEval}
                  data-cy='appointment-type-legacyIsInitialEval'
                />
              )}
            </Row>
            <Row style={rowStyle}>
              <TextField
                fullWidth
                disabled
                label='Require Insurance'
                id='requireInsurance'
                value={`${patientsSingularAlias} will${
                  mappedAppointmentType.shouldRequireManualInsurance
                    ? ''
                    : ' NOT'
                } be required to enter insurance`}
                InputProps={{
                  endAdornment: (
                    <Switch
                      disabled
                      data-cy='require-insurance-switch'
                      name='requireInsuranceSwitch'
                      value={
                        mappedAppointmentType.shouldRequireManualInsurance ===
                        true
                      }
                      checked={
                        mappedAppointmentType.shouldRequireManualInsurance ===
                        true
                      }
                    />
                  ),
                }}
              />
            </Row>
            <Row>
              <TextField
                fullWidth
                label='Patient Request Acknowledgement'
                id='patientRequestAcknowledgement'
                value={`On booking, patients are${
                  shouldShowFinalCheckboxFormState.value ? '' : ' NOT'
                } required to acknowledge they are requesting an appointment`}
                multiline
                disabled
                InputProps={{
                  endAdornment: (
                    <Switch
                      data-cy='patient-acknowledgment-toggle'
                      name='patientRequestAcknowledgementSwitch'
                      disabled
                      value={shouldShowFinalCheckboxFormState.value === true}
                      checked={shouldShowFinalCheckboxFormState.value === true}
                      onChange={(e) => {
                        shouldShowFinalCheckboxFormState.updateValue(
                          e.target.checked
                        );
                      }}
                    />
                  ),
                }}
              />
            </Row>
          </DetailCardBody>
        )}
      </Card>
    </>
  );
};

export default AppointmentTypeCard;
