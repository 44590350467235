import {
  Appointment,
  Conversation,
  useMyUnreadConversationsQuery,
  usePendingAppointmentsQuery,
  usePendingVideoAppointmentsQuery,
  VideoAppointment,
} from '../generated/graphql';
import {
  mapPendingAppointmentsToNotificationData,
  mapUnreadConversationToNotification,
} from '../helpers/mapDataToNotifications';

import Flags from '../constants/flags';
import useFeatureFlagWrapper from '../hooks/useFeatureFlagWrapper';

const useTotalNotificationsCount = () => {
  const featureWrapper = useFeatureFlagWrapper();

  const isSMSFlagNotActive = !featureWrapper<boolean>(
    true,
    Flags.ClinicSmsMessages
  );
  const unreadConversationsQuery = useMyUnreadConversationsQuery({
    pollInterval: 30000,
    fetchPolicy: 'cache-and-network',
    skip: isSMSFlagNotActive,
  });

  const pendingInPersonAppointmentsQuery = usePendingAppointmentsQuery({
    variables: {
      input: {
        limit: 100,
      },
    },
    pollInterval: 30000,
    fetchPolicy: 'cache-and-network',
  });

  const pendingVideoAppointmentsQuery = usePendingVideoAppointmentsQuery({
    variables: {
      input: {
        limit: 100,
      },
    },
    pollInterval: 30000,
    fetchPolicy: 'cache-and-network',
  });
  const pendingVideoAppointments =
    mapPendingAppointmentsToNotificationData({
      appointmentData: pendingVideoAppointmentsQuery.data
        ?.pendingVideoAppointments?.result as VideoAppointment[],
      isInPerson: true,
    }) ?? [];

  const pendingInPersonAppointments =
    mapPendingAppointmentsToNotificationData({
      appointmentData: pendingInPersonAppointmentsQuery.data
        ?.pendingAppointments?.result as Appointment[],
      isInPerson: true,
    }) ?? [];

  const unreadConversations = mapUnreadConversationToNotification(
    (unreadConversationsQuery.data?.myUnreadConversations ??
      []) as Conversation[]
  );

  return [
    ...pendingInPersonAppointments!,
    ...pendingVideoAppointments!,
    ...unreadConversations!,
  ].length;
};

export default useTotalNotificationsCount;
