import React from 'react';
import styled from '@emotion/styled';

//hooks
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import useRemoteConfigAliases from '../../../../../../../hooks/useRemoteConfigAliases';
import useSnackbar from '../../../../../../../hooks/useSnackbar';

import { Button, colorValues } from '@betterpt/better-components';

import { IcDelete } from '@betterpt/better-icons';

import Dialog from '@material-ui/core/Dialog';

//gql
import { gql } from '@apollo/client/core';
import { useArchiveConversationMutation } from '../../../../../../../generated/graphql';
import useHandleError from '../../../../../../../hooks/useHandleError';
// import { MY_UNREAD_CONVERSATIONS } from '../../../../../Notifications/components/NotificationsPanel';

export const ARCHIVE_CONVERSATION_MUTATION = gql`
  mutation ArchiveConversation($id: ID!) {
    archiveConversation(id: $id) {
      id
    }
  }
`;

//styles
const CTASection = styled.div`
  border-top: 1px solid ${colorValues.concretejungle};
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 32px 10px 32px;
`;

const IconButton = styled(Button)`
  border: none !important;
  background-color: transparent !important;
  padding: 0 !important;
  min-width: 0 !important;
`;
const DialogBody = styled.div`
  padding: 20px;
  width: 376px;
  padding: 52px;
`;

const DialogTitle = styled.p`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
`;

const buttonStyle = {
  marginTop: 20,
  marginBottom: 10,
};

interface Props {
  handleSend: () => void;
  sendingMessage: boolean;
}
const ConversationCTASection = ({ handleSend, sendingMessage }: Props) => {
  const { primaryColor } = useRemoteConfigAliases();

  const snackbar = useSnackbar();
  const handleError = useHandleError();

  const { push } = useHistory();
  const { url } = useRouteMatch();

  const { conversationId } = useParams<{
    conversationId: string;
  }>();

  const [archiveConversation] = useArchiveConversationMutation();

  const [isArchiving, updateIsArchiving] = React.useState(false);

  const [isArchiveDialogOpen, updateArchiveDialogOpen] = React.useState(false);

  const handleConfirmArchive = async () => {
    updateIsArchiving(true);
    try {
      await archiveConversation({
        variables: {
          id: conversationId,
        },
        // refetchQueries: [{ query: MY_UNREAD_CONVERSATIONS }],
      });
      updateIsArchiving(false);
      updateArchiveDialogOpen(false);
      snackbar?.openSnackbar({
        isError: false,
        message: 'Conversation Archived Successfully',
      });
      push(url.split('/').slice(0, 4).join('/'));
    } catch (error) {
      updateIsArchiving(false);
      handleError(error);
    }
  };

  return (
    <CTASection>
      {conversationId ? (
        <>
          <Dialog open={isArchiveDialogOpen}>
            <DialogBody>
              <DialogTitle>
                Are you sure you want to archive this chat?
              </DialogTitle>
              <p>
                Archiving this chat will remove it from BetterAccess. Are you
                sure you want to continue?
              </p>
              <Button
                onClick={handleConfirmArchive}
                color='red'
                size='medium'
                fullWidth
                loading={isArchiving}
                style={buttonStyle}
              >
                CONTINUE
              </Button>
              <Button
                onClick={() => updateArchiveDialogOpen(false)}
                color='transparent'
                fullWidth
              >
                NEVER MIND
              </Button>
            </DialogBody>
          </Dialog>
          <IconButton onClick={() => updateArchiveDialogOpen(true)}>
            <IcDelete color={colorValues.orangepink} />
          </IconButton>
        </>
      ) : (
        <div />
      )}

      <Button
        loading={sendingMessage}
        style={{
          backgroundColor: primaryColor,
          width: 153,
          height: 'auto',
          maxHeight: 48,
        }}
        size='large'
        onClick={handleSend}
      >
        SEND
      </Button>
    </CTASection>
  );
};

export default ConversationCTASection;
