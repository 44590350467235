import React from 'react';

//types
import { AppointmentMedium, CompanyAppointmentType } from '../../../generated/graphql';

//hooks
import { useParams } from 'react-router-dom';
import { useAppointmentTypeQuery } from '../../../generated/graphql';
import useSnackbar from '../../../hooks/useSnackbar';
import useAppointmentTypeDetailOperations from './hooks/useAppointmentTypeDetailOperations';
import useFeatureAccessCheck from '../../../hooks/useFeatureAccessCheck';

//components
import AppointmentTypeCard from './components/AppointmentTypeCard';
import AppointmentTypeProviders from './components/AppointmentTypeProviders';
import AppointmentTypeFacilities from './components/AppointmentTypeFacilities';
import BreadCrumbs from './components/BreadCrumbs';
import CustomQuestions from './components/CustomQuestions';
import EditCustomQuestions from './components/RjsfComponents/EditCustomQuestions';
import DefaultQuestionCard from './components/DefaultQuestionCard';
import EditCustomQuestionsNew from './components/CustomLogicComponents/EditCustomQuestionsNew';
import CustomQuestionsNew from './components/CustomQuestionsNew';

//styles
import { Column } from '../../Shared/AppointmentTypes/AppointmentTypes.style';

const appointmentDetailPageStyle = { margin: '40px 28px', maxWidth: '1190px' };

const AppointmentTypeDetail = () => {
  const snackbar = useSnackbar();
  const { appointmentTypeId, employeeId } = useParams<{
    appointmentTypeId: string;
    employeeId?: string;
  }>();
  const appointmentTypeQuery = useAppointmentTypeQuery({
    variables: {
      id: appointmentTypeId!,
    },
    skip: !appointmentTypeId,
    onError: () => snackbar?.useGenericErrorMessage(),
    fetchPolicy: 'cache-and-network',
  });

  const [cascadeQuestions, updateCascadeQuestions] = React.useState<any>([]);
  const [isEditQuestionsOpen, setIsEditQuestionsOpen] = React.useState(false);
  const [isCascadeView, setIsCascadeView] = React.useState(false);

  const {
    commands: {
      updateAppointmentTypeDetails,
      updateAppointmentTypeQuestions,
      setAppointmentTypeProviders,
      toggleArchiveAppointmentType,
    },
    queries: { mapAppointmentTypeDetails },
  } = useAppointmentTypeDetailOperations();

  const { isPT, loading: featuresLoading } = useFeatureAccessCheck();

  const loading = appointmentTypeQuery.loading || featuresLoading;
  const appointmentType:
    | CompanyAppointmentType
    | null
    | undefined = appointmentTypeQuery.data
      ?.appointmentType as CompanyAppointmentType;
  const mappedAppointmentType = React.useMemo(
    () =>
      mapAppointmentTypeDetails({
        appointmentType,
        loading,
      }),
    [appointmentType, loading, mapAppointmentTypeDetails]
  );

  //check if the appointment type has RJSF schema aur custom schema.
  let isCustomLogic = mappedAppointmentType?.customFormTemplate?.hasOwnProperty('schemaVersion');
  const openEditQuestions = () => setIsEditQuestionsOpen(true);
  const closeEditQuestions = () => {
    setIsEditQuestionsOpen(false)
    setIsCascadeView(false);
  };

  const openCascadeView = () => {
    let questionsOrder =
      mappedAppointmentType?.customFormUISchemaTemplate?.['ui:order'] ?? [];
    let required = mappedAppointmentType?.customFormTemplate?.required ?? [];
    let questions = mappedAppointmentType?.customFormTemplate?.properties ?? {};
    const questionType = new Map([
      ['string', 'Open-ended'],
      ['Yes/No', 'Yes/No'],
    ]);
    let cascadeSchema = Object.keys(questions)
      .sort((a, b) => questionsOrder.indexOf(a) - questionsOrder.indexOf(b))
      .map((key, idx) => {
        let type = questions[key]?.enum?.join('/');
        return {
          level: 0,
          id: idx + 1,
          questionType:
            questionType.get(type ?? questions[key]?.type) || 'Multiple Choice',
          questionStatement: key,
          options: (!questionType.get(type) &&
            questions[key]?.enum?.map((option, idx) => ({
              id: idx + 1,
              optionStatement: option,
            }))) || [{ id: 1, optionStatement: '' }],
          required: required.includes(key),
          hasDependency: false,
          parentId: null,
          parentType: null,
          sortOrder: idx + 1,
        };
      });
    updateCascadeQuestions({...mappedAppointmentType, customFormTemplate: {questions: cascadeSchema}});
    setIsCascadeView(true);
    openEditQuestions()
  };

  let mapApptType = {
    true: mappedAppointmentType,
    false: cascadeQuestions,
  }

  return (
    <Column style={appointmentDetailPageStyle}>

      {!isCustomLogic && !isCascadeView ?
        (<EditCustomQuestions
          isOpen={isEditQuestionsOpen}
          handleClose={closeEditQuestions}
          mappedAppointmentType={mappedAppointmentType}
          updateAppointmentTypeQuestions={updateAppointmentTypeQuestions}
          loading={mappedAppointmentType.loading}
        />)
        : (<EditCustomQuestionsNew
          isOpen={isEditQuestionsOpen}
          handleClose={closeEditQuestions}
          mappedAppointmentType={mapApptType[isCustomLogic]}
          updateAppointmentTypeQuestions={updateAppointmentTypeQuestions}
          loading={mappedAppointmentType.loading}
        />)}
      <BreadCrumbs employeeId={employeeId} />
      <AppointmentTypeCard
        loading={mappedAppointmentType.loading}
        appointmentType={appointmentType}
        mappedAppointmentType={mappedAppointmentType}
        updateAppointmentTypeDetails={updateAppointmentTypeDetails}
        toggleArchiveAppointmentType={toggleArchiveAppointmentType}
      />
      {!isCustomLogic ?
        (<CustomQuestions
          mappedAppointmentType={mappedAppointmentType}
          openCascadeView={openCascadeView}
          openEditQuestions={openEditQuestions}
        />)
        : (<CustomQuestionsNew
          mappedAppointmentType={mappedAppointmentType}
          openEditQuestions={openEditQuestions}
        />)}
      {isPT && <DefaultQuestionCard />}
      <AppointmentTypeProviders
        mappedAppointmentType={mappedAppointmentType}
        setAppointmentTypeProviders={setAppointmentTypeProviders}
      />
      <AppointmentTypeFacilities
        mappedAppointmentType={mappedAppointmentType}
      />
    </Column>
  );
};

export default AppointmentTypeDetail;