import React from 'react';
import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

export const SignatureText = styled.p<{ patient?: boolean }>`
  color: ${({ patient }) =>
    patient ? colorValues.charcoalgray : colorValues.emptiness};
  font-size: 12px;
  opacity: ${({ patient }) => (patient ? 0.7 : 0.75)};
  position: absolute;
  white-space: nowrap;
  bottom: 6px;
  right: ${({ patient }) => (patient ? 11 : 9)}px;
  margin: 0 !important;
`;

const Signature = ({
  renderSignature,
  text,
  patient,
}: {
  renderSignature: boolean;
  text: string;
  patient?: boolean;
}) => {
  return renderSignature ? (
    <SignatureText patient={patient}>{text}</SignatureText>
  ) : null;
};

export default Signature;
