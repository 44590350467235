import React from 'react';

//types
import { TextFieldState } from '../../../../../../../hooks/useTextFieldState';

//components
import { Button } from '@betterpt/better-components';

//assets
import { IcClose, IcSearch } from '@betterpt/better-icons';

//styles
import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

const SearchSection = styled.div`
  height: 90px;
  border-top: 1px solid ${colorValues.coneyislandsidewalk};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Input = styled.input`
  margin: auto;
  width: 70%;
  height: 33px;
  padding: 0 10px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 1.14;
  border: 2px solid ${colorValues.concretejungle};
  background-color: ${colorValues.emptiness};
`;

const searchButtonStyle = {
  backgroundColor: 'transparent',
  position: 'absolute' as 'absolute',
  right: 40,
  top: 23,
  padding: 0,
  height: 24,
  width: 26,
  minWidth: 26,
};

const clearSvgStyle = { height: 22, width: 22 };
const searchSvgStyle = {
  ...clearSvgStyle,
  position: 'absolute' as 'absolute',
  right: 39,
  top: 23,
};

interface Props {
  searchText: TextFieldState;
}

const SearchInput = ({ searchText }: Props) => {
  return (
    <SearchSection>
      <Input
        placeholder='Search names, phone numbers'
        value={searchText.value}
        onChange={(e) => searchText.updateValue(e.target.value)}
      />
      {searchText.value.length ? (
        <Button
          aria-label='clear search'
          style={searchButtonStyle}
          onClick={() => searchText.updateValue('')}
        >
          <IcClose color={colorValues.concretejungle} style={clearSvgStyle} />
        </Button>
      ) : (
        <IcSearch color={colorValues.concretejungle} style={searchSvgStyle} />
      )}
    </SearchSection>
  );
};

export default SearchInput;
