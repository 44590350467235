import { jsx } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';

// Icons
import IcArrowRight from '../Icons/IcArrowRight';
import IcArrowLeft from '../Icons/IcArrowLeft';

// constants
import { colorValues } from '../helpers/colors';

// styles
import { arrowStyle, imageSection, mainSection } from './style';

type Props = {
  images: Array<string>;
  width?: string;
  placeholder?: string;
  style?: React.CSSProperties;
};

const Div = styled.div``;
const Button = styled.button``;

/**
 * Component that Displays a list of images default width is 100% of parent.
 *
 * @param {Props} { images, width }
 * @returns
 */
const DisplayImages = ({ images, width, placeholder, style }: Props) => {
  const [activeImageIdx, setActiveImageIdx] = React.useState(0);
  const totalImages = images.length;

  return (
    <Div style={style} css={mainSection(width)}>
      {totalImages > 1 && (
        <Button
          css={arrowStyle}
          onClick={() =>
            setActiveImageIdx(
              activeImageIdx === 0 ? totalImages - 1 : activeImageIdx - 1
            )
          }
        >
          <IcArrowLeft color={colorValues.concretejungle} />
        </Button>
      )}
      <Div css={imageSection}>
        <img
          src={images[activeImageIdx] || placeholder}
          alt={images[activeImageIdx] || placeholder}
        />
      </Div>
      {totalImages > 1 && (
        <Button
          css={arrowStyle}
          onClick={() =>
            setActiveImageIdx(
              activeImageIdx === totalImages - 1 ? 0 : activeImageIdx + 1
            )
          }
        >
          <IcArrowRight color={colorValues.concretejungle} />
        </Button>
      )}
    </Div>
  );
};

export default DisplayImages;
