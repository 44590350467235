import React from 'react';
import styled from '@emotion/styled';

import { ImgDanBeard } from '@betterpt/better-icons';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-width: 675px;
  max-width: 1030px;
`;

const NoMessagesContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 322px;
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 50px;
`;

const Body = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
`;

const NoMessagesYet = () => {
  return (
    <Wrapper>
      <NoMessagesContent>
        <ImgDanBeard />
        <Title>No Mesages Yet</Title>
        <Body>
          Start a new conversation by clicking on the pencil icon on the right
          side of the messaging column.
        </Body>
      </NoMessagesContent>
    </Wrapper>
  );
};

export default NoMessagesYet;
