import React from 'react';

//types
import { Conversation } from '../../../../../../../generated/graphql';

//helpers
import { isBefore } from 'date-fns';
import { colorValues } from '@betterpt/better-components';

//hooks
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import useDebounce from '../../../../../../../hooks/useDebounce';
import useSnackbar from '../../../../../../../hooks/useSnackbar';
import useTextFieldState from '../../../../../../../hooks/useTextFieldState';
import { useConversationsQuery } from '../../../../../../../generated/graphql';

// components
import NewConversationButton from './NewConversationButton';
import ConversationPreviewListItem from './ConversationPreviewListItem';
import SearchInput from './SearchInput';
import { Button, Card, Header } from '@betterpt/better-components';

//assets
import { IcLoading } from '@betterpt/better-icons';

//styles
import styled from '@emotion/styled';
const CardStyle = {
  height: '75vh',
  minHeight: 592,
  marginRight: 20,
  minWidth: 302,
  display: 'flex',
  flexDirection: 'column' as 'column',
};

const CardBody = styled.div<{ isEmpty: boolean }>`
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ isEmpty }) => (isEmpty ? 'center' : 'top')};
  justify-content: ${({ isEmpty }) => (isEmpty ? 'center' : 'flex-start')};
`;

const NoHistory = styled.p`
  font-size: 16px;
  font-weight: bold;
  color: ${colorValues.charcoalgray};
`;

const P = styled.p`
  font-size: 16px;
  font-weight: bold;
`;

const resetSearchButtonStyle = {
  color: colorValues.cityblock,
  backgroundColor: 'transparent',
  fontSize: 14,
  paddingLeft: 5,
  width: 82,
};

const sortByTimestamp = (toSort: Conversation[]) =>
  [...toSort].sort((a, b) => {
    const aDate = new Date(a?.messages?.[0]?.timestamp ?? new Date());
    const bDate = new Date(b?.messages?.[0]?.timestamp ?? new Date());
    if (isBefore(bDate, aDate)) return -1;
    if (isBefore(aDate, bDate)) return 1;
    else return 0;
  });

interface Props {
  facilityId: string;
}
const ConversationsList = ({ facilityId }: Props) => {
  const { url } = useRouteMatch();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const snackbar = useSnackbar();

  const [conversations, updateConversations] = React.useState<Conversation[]>(
    []
  );
  const [newInteract, updateNewInteract] = React.useState(false);
  const searchText = useTextFieldState();
  const debouncedSearchText = useDebounce(searchText.value, 500);

  const queryConversations = useConversationsQuery({
    variables: { clinicId: facilityId },
    pollInterval: 5000,
  });
  const conversationsResult = queryConversations.data?.conversations;
  const loading = queryConversations.loading;
  const searching = debouncedSearchText.length !== searchText.value.length;
  const displaySearch = !!searchText.value.length || searching;

  React.useEffect(() => {
    if (conversationsResult) {
      const sortedByMostRecentTimestamp = sortByTimestamp(
        conversationsResult as Conversation[]
      );
      if (!!debouncedSearchText.length) {
        const activeSearchText = debouncedSearchText.toLowerCase();
        const activeSearchNumbers = activeSearchText.replace(/[^0-9+]/g, '');
        const activeSearchLetters = activeSearchText.replace(/[^a-zA-Z]/g, '');

        let filteredBySearchText = sortedByMostRecentTimestamp;
        if (!!activeSearchNumbers.length) {
          //preferentially filter by phone numbers (numbers or +)
          filteredBySearchText = filteredBySearchText.filter(
            (conversation) =>
              !!conversation.patientPhone?.includes(activeSearchNumbers)
          );
        }
        if (!!activeSearchLetters.length) {
          //secondarily filter again by letters
          filteredBySearchText = filteredBySearchText.filter(
            (conversation) =>
              !!conversation.patientName
                ?.toLowerCase()
                .includes(activeSearchLetters)
          );
        }
        updateConversations(filteredBySearchText ?? []);
      } else updateConversations(sortedByMostRecentTimestamp ?? []);
    }
  }, [queryConversations, conversationsResult, debouncedSearchText]);

  const handleNewMessageButton = (
    e:
      | React.KeyboardEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (pathname === `${url}/new-conversation`) {
      snackbar?.setErrorMessage('Use the form below to draft a new message');
    } else if ((e as React.KeyboardEvent<HTMLDivElement>)?.key === 'Enter') {
      push(`${url}/new-conversation`);
    }
  };

  return (
    <Card width={302} style={CardStyle}>
      <Header
        underline
        style={{ justifyContent: 'space-between', position: 'relative' }}
      >
        <P>Messaging</P>
        <NewConversationButton
          url={url}
          userInteracting={newInteract}
          handleInteract={updateNewInteract}
          handleNewMessageButton={handleNewMessageButton}
        />
      </Header>
      <CardBody isEmpty={!conversations.length}>
        {!conversations.length && !loading ? (
          <>
            <NoHistory>
              {displaySearch ? 'No results' : 'No message history yet'}
            </NoHistory>
            {displaySearch && (
              <Button
                size='small'
                onClick={() => searchText.updateValue('')}
                loading={searching}
                style={resetSearchButtonStyle}
              >
                <IcLoading
                  color={colorValues.cityblock}
                  style={{ width: 18, height: 18 }}
                />
                RESET
              </Button>
            )}
          </>
        ) : (
          conversations.map(
            (conversation) =>
              conversation && (
                <ConversationPreviewListItem
                  key={conversation.id}
                  conversation={conversation}
                />
              )
          )
        )}
      </CardBody>
      <SearchInput searchText={searchText} />
    </Card>
  );
};

export default ConversationsList;
