import React from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { ApolloProvider } from '@apollo/client';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import { AuthProvider } from './context/authentication';
import { RemoteConfigProvider } from './context/remoteConfig';
import { IntercomWrapper } from '@betterpt/shared';

// config
import client from './config/apollo';

// components
import Home from './components/Home';
import Login from './components/authentication/Login';
import Signup from './components/authentication/Signup';
import ConfirmEmail from './components/authentication/ConfirmEmail';
import ProtectedRoute from './components/ProtectedRoute';
import ErrorBoundary from './components/ErrorBoundary';
import ForgotPassword from './components/authentication/ForgotPassword';
import ForgotPasswordSubmit from './components/authentication/ForgotPasswordSubmit';
import Onboarding from './components/Onboarding';
import ChangePassword from './components/authentication/ChangePassword.tsx';
import FederatedLogin from './components/authentication/FederatedLogin';

// style
import './App.css';
import { firebaseAnalytics } from './config/firebase';
import TermsOfUse from './components/Views/TermsOfUse';
import PrivacyPolicy from './components/Views/PrivacyPolicy';
import AcceptTermsDialog from './components/Onboarding/AcceptTermsDialog';

const HistoryListener = () => {
  const location = useLocation();

  React.useEffect(() => {
    firebaseAnalytics.logEvent('page_view', {
      page_path: location.pathname,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div />;
};

const App = () => {
  return (
    <ApolloProvider client={client}>
      <IntercomWrapper isPatient>
        <AuthProvider>
          <RemoteConfigProvider>
            <ErrorBoundary>
              <Router>
                <AcceptTermsDialog />
                <HistoryListener />
                <Switch>
                  <Route exact path={['/login', '/login/:variant']}>
                    <Login />
                  </Route>
                  <Route
                    exact
                    path={['/hss-login', '/select-login', '/federated-login']}
                  >
                    <FederatedLogin />
                  </Route>
                  <Route
                    exact
                    path={['/confirm/:email', '/confirm/:variant/:email']}
                  >
                    <ConfirmEmail />
                  </Route>
                  <Route
                    exact
                    path={['/forgot-password', '/forgot-password/:variant']}
                  >
                    <ForgotPassword />
                  </Route>
                  <Route
                    exact
                    path={[
                      '/forgot-password-submit/:email',
                      '/forgot-password-submit/:variant/:email',
                    ]}
                  >
                    <ForgotPasswordSubmit />
                  </Route>
                  <Route
                    exact
                    path={[
                      '/change-password/:email',
                      '/change-password/:variant/:email',
                    ]}
                  >
                    <ChangePassword />
                  </Route>
                  <Route exact path='/terms-of-use'>
                    <TermsOfUse />
                  </Route>
                  <Route exact path='/privacy-policy'>
                    <PrivacyPolicy />
                  </Route>
                  <ProtectedRoute path='/onboarding'>
                    <Onboarding />
                  </ProtectedRoute>
                  <ProtectedRoute path='/'>
                    <Home />
                  </ProtectedRoute>
                </Switch>
              </Router>
            </ErrorBoundary>
          </RemoteConfigProvider>
        </AuthProvider>
      </IntercomWrapper>
    </ApolloProvider>
  );
};

export default withLDProvider({
  clientSideID:
    window.__RUNTIME_CONFIG__.REACT_APP_LD_CLIENT_ID ?? '612e38940c89df2645bab21f',
  user: {
    key: 'unidentified_user',
    anonymous: true,
  },
})(App);
