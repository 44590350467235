import React from 'react';
import styled from '@emotion/styled';
import { Button } from '@betterpt/better-components';
import Can from '../../../Can';
import { Roles } from '../../../../helpers';
import { useMeQuery } from '../../../../generated/graphql';

const NoQuestionsContainer = styled.div`
  margin: 10px 24px 35px 50px;
  padding: 17.5px 22px 17.5px 26px;
  border-radius: 5px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0px;
  }
`;

interface Props {
  openEditQuestions(): void;
}

const NoCustomQuestions: React.FC<React.PropsWithChildren<Props>> = ({ openEditQuestions }) => {
  const meQuery = useMeQuery();
  return (
    <NoQuestionsContainer>
      <p className='H1'>
        There are currently no qualifying questions for this appointment type.
      </p>

      <Can
        role={Roles[meQuery.data?.me?.role ?? 'initial']}
        perform='appointmentType:edit'
        yes={() => (
          <Button
            color='ibizaTeal'
            onClick={openEditQuestions}
            size='medium'
            style={{ width: '159px' }}
          >
            ADD NOW!
          </Button>
        )}
      />
    </NoQuestionsContainer>
  );
};

export default NoCustomQuestions;
