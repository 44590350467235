import React from 'react';
import styled from '@emotion/styled';
import { format } from 'date-fns';

import { NavLink, useRouteMatch } from 'react-router-dom';

//variables
import { colorValues } from '@betterpt/better-components';

// types
import { Conversation } from '../../../../../../../generated/graphql';
import { normalizePhone } from '../../../../../../../helpers';

const ListItemContainer = styled.div`
  border-bottom: 1px;
  a {
    color: ${colorValues.betterptblack};
  }
  .active .body {
    background-color: ${colorValues.frost} !important;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.p`
  font-size: 12px;
  font-weight: bold;
  margin: 0;
`;
const Message = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  margin-bottom: 0px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Body = styled.div`
  height: 97;
  padding: 19px 24px;
  border-bottom: 1px solid ${colorValues.concretejungle};
`;

const DateTag = styled.p`
  font-size: 12px;
  font-weight: bold;
  color: ${colorValues.charcoalgray};
  margin: 0;
  width: 50px;
`;

const Tag = styled.p`
  color: ${colorValues.emptiness};
  background-color: ${colorValues.orangepink};
  width: 38px;
  height: 14px;
  padding: 0;
  margin: 0;
  margin-right: 7px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
`;

const TitleSideContainer = styled.div`
  display: flex;
`;

interface Props {
  conversation: Conversation;
}
const ConversationPreviewListItem = ({ conversation }: Props) => {
  const { url } = useRouteMatch();
  const title =
    conversation.patientName ?? normalizePhone(conversation.patientPhone);
  const message = conversation?.mostRecentMessage?.text ?? '';
  const date = conversation?.mostRecentMessage?.timestamp
    ? format(
        new Date(conversation?.mostRecentMessage?.timestamp ?? null),
        'LLL d.'
      )
    : '';

  const isNewMessage =
    !conversation.mostRecentMessage?.firstViewedAt &&
    !conversation.mostRecentMessage?.firstViewedByEmployeeId;
  return (
    <ListItemContainer>
      <NavLink to={`${url}/${conversation.id}/conversation`}>
        <Body className='body'>
          <TitleContainer>
            <Title>{title}</Title>
            <TitleSideContainer>
              {isNewMessage ? <Tag>NEW !</Tag> : <div />}
              <DateTag>{date}</DateTag>
            </TitleSideContainer>
          </TitleContainer>
          <Message>{message}</Message>
        </Body>
      </NavLink>
    </ListItemContainer>
  );
};

export default ConversationPreviewListItem;
