import React from 'react';

//types
import { UpdateConversationInput } from '../../../hooks/useConversationOperations';
import { TextFieldState } from '../../../../../../../../hooks/useTextFieldState';

//hooks
import useSnackbar from '../../../../../../../../hooks/useSnackbar';
import useTextFieldState from '../../../../../../../../hooks/useTextFieldState';

//components
import AddChatParticipantDialog from '../../AddChatParticipantDialog';
import InfoButtonAndFields from './InfoButtonAndFields';

//styles
import styled from '@emotion/styled';
import useHandleError from '../../../../../../../../hooks/useHandleError';

const ParticipantInfo = styled.div`
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  align-self: flex-start;
`;

export enum ParticipantInfoFieldState {
  noInfoNewConvo = 'noInfoNewConvo',
  noNameNewConvo = 'noNameNewConvo',
  yesInfo = 'yesInfo',
  noName = 'noName',
  noPhone = 'noPhone',
}

interface Props {
  name: string;
  phone: Partial<TextFieldState>;
  updateConversation: (props: UpdateConversationInput) => Promise<any>;
  conversationId?: string;
  handleNameChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  openNewConvoDialog?: () => void;
  loading?: boolean;
}

const ChatParticipantInfo = ({
  conversationId,
  name,
  phone,
  updateConversation,
  handleNameChange,
  openNewConvoDialog,
  loading,
}: Props) => {
  const snackbar = useSnackbar();
  const handleError = useHandleError();

  const noName = !name.trim().length;
  const noPhone = !phone.value?.trim().length;
  const newConvo = !!openNewConvoDialog;

  const [fieldState, updateFieldState] = React.useState<
    ParticipantInfoFieldState | undefined
  >();
  const [internalDialogOpen, updateDialogOpen] = React.useState(false);
  const currentName = useTextFieldState({ initialValue: name });

  React.useEffect(() => {
    currentName.updateValue(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  React.useEffect(() => {
    if (!loading) {
      if (newConvo) {
        if (noName && noPhone) {
          updateFieldState(ParticipantInfoFieldState.noInfoNewConvo);
        } else if (noName && !noPhone) {
          updateFieldState(ParticipantInfoFieldState.noNameNewConvo);
        } else if (!noName && noPhone) {
          updateFieldState(ParticipantInfoFieldState.noPhone);
        } else if (!noName && !noPhone) {
          updateFieldState(ParticipantInfoFieldState.yesInfo);
        }
      } else {
        if (noName && !noPhone) {
          updateFieldState(ParticipantInfoFieldState.noName);
        } else if (!noName && noPhone) {
          updateFieldState(ParticipantInfoFieldState.noPhone);
        } else if (!noName && !noPhone) {
          updateFieldState(ParticipantInfoFieldState.yesInfo);
        }
      }
    }
  }, [loading, noName, noPhone, newConvo]);

  const handleEditSaveNameButton = async () => {
    if (currentName.value !== name) {
      try {
        await updateConversation({
          conversationId,
          patientName: currentName.value,
        });
        snackbar?.openSnackbar({ isError: true, message: 'Name updated' });
        updateDialogOpen(false);
      } catch (error) {
        handleError(error);
      }
    } else updateDialogOpen(false);
  };

  const onClick = () =>
    openNewConvoDialog ? openNewConvoDialog() : updateDialogOpen(true);

  return (
    <>
      <AddChatParticipantDialog
        open={internalDialogOpen}
        onClose={() => updateDialogOpen(false)}
        phone={phone}
        name={currentName}
        header={
          name.length
            ? "Edit chat participant's name"
            : "Add chat participant's name"
        }
        CTA={name.length ? 'EDIT NAME' : 'ADD NAME'}
        handleButton={handleEditSaveNameButton}
      />
      <ParticipantInfo>
        <InfoButtonAndFields
          loading={!!loading}
          fieldState={fieldState}
          onClick={onClick}
          phone={phone}
          name={name}
          handleNameChange={handleNameChange}
        />
      </ParticipantInfo>
    </>
  );
};

export default ChatParticipantInfo;
