import React from 'react';
import styled from '@emotion/styled';
import { Route, useRouteMatch, useParams } from 'react-router';

// Components
import ConversationDetail from './components/ConversationDetail';
import ConversationsList from './components/ConversationsList';
import NewConversation from './components/NewConversation';
import NoMessagesYet from './components/NoMessagesYet';
import {
  Animation,
  Button,
  Card,
  colorValues,
} from '@betterpt/better-components';
import { ImgDanBeard } from '@betterpt/better-icons';

// hooks
import useConversationOperations from './hooks/useConversationOperations';
import {
  useConversationsQuery,
  useFacilityQuery,
} from '../../../../../generated/graphql';
import useRemoteConfigAliases from '../../../../../hooks/useRemoteConfigAliases';

//styles
const View = styled.div`
  display: flex;
`;

const cardStyle = {
  height: 823,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const NotSignedContainer = styled.div`
  width: 476px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NotSignedTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
  margin-top: 50px;
`;

const NotSignedUpBody = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  margin-bottom: 46px;
  a {
    color: ${colorValues.frenchblue};
  }
`;

const SMSMEssagesTab = () => {
  const { primaryColor } = useRemoteConfigAliases();
  const { url } = useRouteMatch();
  const { facilityId } = useParams<{ facilityId: string }>();

  const {
    commands: {
      createConversation,
      updateConversation,
      addMessageToConversation,
      markConversationAsRead,
    },
  } = useConversationOperations();

  const conversationsQuery = useConversationsQuery({
    variables: { clinicId: facilityId },
  });

  const facilityQuery = useFacilityQuery({ variables: { id: facilityId } });

  const loading = conversationsQuery.loading || facilityQuery.loading;

  const isSMSInactive = !facilityQuery.data?.clinic?.smsConfig?.smsPhoneNumber;
  const noMessageHistory = !conversationsQuery.data?.conversations?.length;

  if (isSMSInactive && !loading) {
    return (
      <>
        <Card style={cardStyle}>
          <NotSignedContainer>
            <ImgDanBeard />
            <NotSignedTitle>
              You Are Not Signed Up for the Beta SMS Service{' '}
            </NotSignedTitle>
            <NotSignedUpBody>
              You are not currently signed up for our Beta SMS Service. To sign
              up, please reach out to us at{' '}
              <a
                href='mailto:contact@betterhealthcare.co'
                target='_blank'
                rel='noreferrer'
              >
                contact@betterhealthcare.co
              </a>{' '}
              and we will add you to our waiting list.
            </NotSignedUpBody>

            <a
              href='mailto:contact@betterhealthcare.co'
              target='_blank'
              rel='noreferrer'
              style={{ width: '100%' }}
            >
              <Button
                size='large'
                style={{ width: '100%', backgroundColor: primaryColor }}
              >
                CONTACT US AND SIGN UP
              </Button>
            </a>
          </NotSignedContainer>
        </Card>
      </>
    );
  }

  return (
    <>
      {loading ? (
        <Animation type='providerAppLoader' />
      ) : (
        <View>
          <ConversationsList facilityId={facilityId} />
          <Route path={`${url}/:conversationId/conversation`} exact>
            <ConversationDetail
              facilityId={facilityId}
              addMessageToConversation={addMessageToConversation}
              updateConversation={updateConversation}
              markConversationAsRead={markConversationAsRead}
            />
          </Route>
          <Route path={`${url}/new-conversation`} exact>
            <NewConversation
              facilityId={facilityId}
              createConversation={createConversation}
              updateConversation={updateConversation}
            />
          </Route>

          <Route path={`${url}`} exact>
            {noMessageHistory ? (
              <NoMessagesYet />
            ) : (
              <NewConversation
                facilityId={facilityId}
                createConversation={createConversation}
                updateConversation={updateConversation}
              />
            )}
          </Route>
        </View>
      )}
    </>
  );
};

export default SMSMEssagesTab;
