import React from 'react';

//types
import {
  StartConversationInput,
  UpdateConversationInput,
} from '../hooks/useConversationOperations';

// components
import { Card, Header } from '@betterpt/better-components';
import ChatParticipantInfo from './ConversationDetail/ChatParticipantInfo';
import NewMessageSection from '../components/ConversationDetail/NewMessageSection';
import ConversationCTASection from '../components/ConversationDetail/ConversationCTASection';
import AddChatParticipantDialog from './AddChatParticipantDialog';

// helpers
import {
  normalizePhone,
  normalizePhoneForSMSDisplay,
  formatPhoneForBackend,
} from '../../../../../../helpers';

//hooks
import useSnackbar from '../../../../../../hooks/useSnackbar';
import useTextFieldState from '../../../../../../hooks/useTextFieldState';
import { useFacilityQuery } from '../../../../../../generated/graphql';

// styles
import styled from '@emotion/styled';
import useHandleError from '../../../../../../hooks/useHandleError';
const CardStyle = {
  width: '100%',
  height: '75vh',
  minHeight: 592,
  minWidth: 675,
  maxWidth: 1030,
  display: 'flex',
  flexDirection: 'column' as 'column',
};

const P = styled.p`
  font-size: 16px;
  font-weight: bold;
`;

const ConversationBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FutureMessagesPlaceholder = styled.div`
  height: calc(75vh - 387px);
  min-height: 212px;
  padding-bottom: 50px;
`;

const patientInfoStyle = {
  height: 67,
  minHeight: 67,
};

interface Props {
  facilityId: string;
  createConversation: (props: StartConversationInput) => Promise<any>;
  updateConversation: (props: UpdateConversationInput) => Promise<any>;
}

const NewConversation = ({
  facilityId,
  createConversation,
  updateConversation,
}: Props) => {
  const snackbar = useSnackbar();
  const handleError = useHandleError();

  const [dialogOpen, updateDialogOpen] = React.useState(false);
  const phone = useTextFieldState({
    required: true,
    normalizer: normalizePhone,
  });
  const name = useTextFieldState();
  const message = useTextFieldState();
  const [sendingMessage, updateSendingMessage] = React.useState(false);

  const facilityQuery = useFacilityQuery({ variables: { id: facilityId } });

  const handleSend = async () => {
    updateSendingMessage(true);
    try {
      await createConversation({
        clinicId: facilityId,
        patientPhone: formatPhoneForBackend(phone.value),
        patientName: name.value,
        message,
      });
      name.updateValue('');
      message.updateValue('');
      updateSendingMessage(false);
    } catch (err) {
      updateSendingMessage(false);
      handleError(err);
    }
  };

  const handleStartConvoButton = () => {
    if (phone.error) {
      snackbar?.setErrorMessage('Mobile phone number is required');
    } else updateDialogOpen(false);
  };

  const facilityPhone = facilityQuery.loading
    ? ''
    : ` ${normalizePhoneForSMSDisplay(
        facilityQuery.data?.clinic?.smsConfig?.smsPhoneNumber
      )}`;

  return (
    <>
      <AddChatParticipantDialog
        open={dialogOpen}
        onClose={() => updateDialogOpen(false)}
        phone={phone}
        name={name}
        header='Add chat participant'
        CTA='ADD CHAT PARTICIPANT'
        handleButton={handleStartConvoButton}
      />
      <Card style={CardStyle}>
        <Header underline style={{ alignItems: 'flex-start' }}>
          <P>
            New Message from {facilityQuery.data?.clinic?.clinicName ?? ''}
            {` ${facilityPhone}`}
          </P>
        </Header>
        <Header underline style={patientInfoStyle}>
          <ChatParticipantInfo
            name={name.value}
            phone={phone}
            loading={facilityQuery.loading}
            updateConversation={updateConversation}
            handleNameChange={(e) => name.updateValue(e.target.value)}
            openNewConvoDialog={() => updateDialogOpen(true)}
          />
        </Header>
        <ConversationBody>
          <FutureMessagesPlaceholder />
          <NewMessageSection
            value={message.value}
            onChange={(e) => message.updateValue(e.target.value)}
          />
          <ConversationCTASection
            handleSend={handleSend}
            sendingMessage={sendingMessage}
          />
        </ConversationBody>
      </Card>
    </>
  );
};

export default NewConversation;
