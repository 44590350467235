import React from 'react';

//types
import { MappedAppointmentTypeDetail } from '../hooks/useAppointmentTypeDetailOperations';

//components
import {
  Animation,
  Button,
  Header,
  InfoBox,
  colorValues,
} from '@betterpt/better-components';
import { GridCard } from '../../../Shared/GridCard';
import DisplayQuestion from './DisplayQuestion';
import NoCustomQuestions from './NoCustomQuestions';
import Can from '../../../Can';
import styled from '@emotion/styled';
import FeatureFlagCheck from '../../../Shared/FeatureFlagCheck';
import Flags from '../../../../constants/flags';

import { Roles } from '../../../../helpers';
import { useMeQuery } from '../../../../generated/graphql';

//styles
const headerStyle = { padding: '15px 25px 10px 50px' };
const infoboxIconStyle = { top: '4px', right: '-140px' };
const infoboxTextStyle = { fontWeight: 'normal' as 'normal' };
const infoboxStyle = { top: '40px', left: '-20px' };

export const CardTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const NonCascadeIcon = styled.div`
  color: ${colorValues.emptiness};
  background-color: ${colorValues.messyketchup};
  font-weight: bold;
  font-size: 10px;
  padding: 2px 20px;
  border-radius: 3px;
  white-space: nowrap;
`;

interface Props {
  openEditQuestions: () => void;
  openCascadeView: () => void;
  mappedAppointmentType: MappedAppointmentTypeDetail;
}

const CustomQuestions: React.FC<React.PropsWithChildren<Props>> = ({
  openEditQuestions,
  openCascadeView,
  mappedAppointmentType,
}) => {
  const properties = mappedAppointmentType.customFormTemplate?.properties ?? {};
  const questionOrder =
    mappedAppointmentType.customFormUISchemaTemplate?.['ui:order'] ?? [];

  const meQuery = useMeQuery();

  return (
    <GridCard fullWidth>
      {mappedAppointmentType.loading ? (
        <Animation type='providerAppLoader' />
      ) : (
        <>
          <Header style={headerStyle} underline>
            <FeatureFlagCheck
              flag={Flags.UpdateSchemaToCascadingLogic}
              yes={() => (
                <CardTitle>
                  <h2 className="H3">Qualifying Questions</h2>
                  <InfoBox
                    openWith="hover"
                    width={230}
                    height={200}
                    iconHeight={14}
                    iconWidth={32}
                    position="bottom"
                    padding={0}
                    fixedPlacement
                    mainStyle={infoboxIconStyle}
                    boxStyle={infoboxStyle}
                    icon={<NonCascadeIcon>NON-CASCADING</NonCascadeIcon>}
                  >
                    <h4>NON-CASCADING</h4>
                    <p style={infoboxTextStyle}>
                      These appointment types still do not use cascading logic.
                      Click into the appointment type details and scroll to the
                      qualifying questions section to update to the new schema
                      and activate this feature.
                    </p>
                  </InfoBox>
                </CardTitle>
              )}
              no={() => <h2 className="H3">Qualifying Questions</h2>}
            />
            {questionOrder.length > 0 && (
              <Can
                role={Roles[meQuery.data?.me?.role ?? 'initial']}
                perform="appointmentType:edit"
                yes={() => (
                  <FeatureFlagCheck
                    flag={Flags.UpdateSchemaToCascadingLogic}
                    yes={() => (
                      <div>
                        <Button
                          style={{ margin: '0px 10px' }}
                          color="ibizaTeal"
                          size="small"
                          onClick={openCascadeView}
                        >
                          UPDATE TO CASCADING LOGIC
                        </Button>
                        <Button
                          color="gray"
                          size="small"
                          onClick={openEditQuestions}
                        >
                          EDIT
                        </Button>
                      </div>
                    )}
                    no={() => (
                      <Button
                        color="gray"
                        size="small"
                        onClick={openEditQuestions}
                      >
                        EDIT
                      </Button>
                    )}
                  />
                )}
              />
            )}
          </Header>
          {questionOrder.length ? (
            questionOrder.map((question: string, index: number) => (
              <DisplayQuestion
                key={question}
                question={question}
                details={properties[question]}
                mappedAppointmentType={mappedAppointmentType}
                questionNumber={index + 1}
              />
            ))
          ) : (
            <NoCustomQuestions openEditQuestions={openEditQuestions} />
          )}
        </>
      )}
    </GridCard>
  );
};

export default CustomQuestions;
