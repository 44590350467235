import React from 'react';

//helpers
import { colorValues, changeHexColor } from '@betterpt/better-components';

//components
import { Link } from 'react-router-dom';

//assets
import { IcEdit } from '@betterpt/better-icons';

//styles
import styled from '@emotion/styled';
const CircleButton = styled.div<{ color: string }>`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  &:hover {
    background-color: ${({ color }) => changeHexColor(color, -15)};
  }
`;

interface Props {
  url: string;
  userInteracting: boolean;
  handleInteract: (userInteracting: boolean) => void;
  handleNewMessageButton: (
    e:
      | React.KeyboardEvent<HTMLDivElement>
      | React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
}

const NewConversationButton = ({
  url,
  userInteracting,
  handleInteract,
  handleNewMessageButton,
}: Props) => (
  <Link
    to={`${url}/new-conversation`}
    role='button'
    aria-label='new message'
    onFocus={() => handleInteract(true)}
    onBlur={() => handleInteract(false)}
  >
    <CircleButton
      color={
        userInteracting
          ? changeHexColor(colorValues.ibizateal, -15)
          : colorValues.ibizateal
      }
      onClick={handleNewMessageButton}
      onKeyDown={handleNewMessageButton}
    >
      <IcEdit color={colorValues.emptiness} />
    </CircleButton>
  </Link>
);

export default NewConversationButton;
