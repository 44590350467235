import React from 'react';

//types
import { TextFieldState } from '../../../../../../../../hooks/useTextFieldState';
import { ParticipantInfoFieldState } from './ChatParticipantInfo';

//helpers
import { normalizePhoneForSMSDisplay } from '../../../../../../../../helpers';

//hooks
import useRemoteConfigAliases from '../../../../../../../../hooks/useRemoteConfigAliases';

//assets
import { IcAddCircle, IcEdit } from '@betterpt/better-icons';

//styles
import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

const AddInfoButton = styled.button`
  width: 244px;
  height: 36px;
  border-radius: 23.5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: ${colorValues.charcoalgray};
  background-color: ${colorValues.emptiness};
  font-weight: bold;
  margin-bottom: 4px;
  margin-left: 20px;
  border: none;
  transition: all 0.3s ease;
  box-shadow: 0 0 4px 0 rgba(51, 51, 51, 0.47),
    0 2px 2px 0 rgba(51, 51, 51, 0.24);
  &:hover,
  &:focus {
    background-color: ${colorValues.dust};
  }
`;

const Input = styled.input<{ disabled?: boolean }>`
  border: none;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: bold;
  line-height: 1.14;
  letter-spacing: normal;
  padding: 10px 0 0;
  margin: 0 0 4px 32px;
  color: ${colorValues.betterptblack};
  background-color: ${colorValues.emptiness};
  border-bottom: ${({ disabled }) => {
    if (disabled === false && disabled !== undefined) {
      return `2px solid ${colorValues.guajirogreen}`;
    } else return 'none';
  }};
  width: min-content;
  overflow: auto;
  &:placeholder {
    color: ${colorValues.charcoalgray};
  }
  &:focus {
    outline: none;
  }
`;

const EditButton = styled.button<{
  primaryColor: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 0;
  border-radius: 50%;
  border: none;
  position: absolute;
  width: 20px;
  height: 20px;
  left: 0;
  top: 43px;
`;

const To = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  position: absolute;
  left: 0;
  top: 10px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const phoneInputStyle = { fontSize: 14, color: colorValues.charcoalgray };
const addSingleFieldStyles = { marginLeft: '-12px' };

const Container = ({
  children,
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => (
  <Column>
    <To>To:</To>
    {children}
  </Column>
);

interface Props {
  loading: boolean;
  fieldState: ParticipantInfoFieldState | undefined;
  onClick: () => void;
  phone: Partial<TextFieldState>;
  name: string;
  handleNameChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const InfoButtonAndFields = ({
  loading,
  fieldState,
  onClick,
  phone,
  name,
  handleNameChange,
}: Props) => {
  const { primaryColor } = useRemoteConfigAliases();

  if (loading) {
    return <div />;
  }

  switch (fieldState) {
    case ParticipantInfoFieldState.noInfoNewConvo:
      //a new conversation without any info
      return (
        <Container>
          <AddInfoButton
            onClick={onClick}
            style={{ ...addSingleFieldStyles, marginTop: 32 }}
          >
            <IcAddCircle color={colorValues.guajirogreen} />
            Add Chat Participant's Info
          </AddInfoButton>
        </Container>
      );
    case ParticipantInfoFieldState.yesInfo:
      //any conversation with both a name and phone number
      return (
        <Container>
          <Input
            onChange={() => {}}
            value={name}
            placeholder='Type name here'
            disabled
            style={{ fontSize: 16, paddingBottom: 5, marginBottom: 5 }}
          />
          <EditButton
            aria-label='Edit name'
            onClick={onClick}
            primaryColor={primaryColor}
          >
            <IcEdit color={primaryColor} />
          </EditButton>
          <Input
            disabled
            value={normalizePhoneForSMSDisplay(phone.value)}
            onChange={() => {}}
            style={phoneInputStyle}
          />
        </Container>
      );
    case ParticipantInfoFieldState.noNameNewConvo:
      //a new conversation without name, with phone (phone can still be edited)
      return (
        <Container>
          <Input
            onChange={(e) => phone.updateValue?.(e.target.value)}
            value={normalizePhoneForSMSDisplay(phone.value)}
            placeholder='Type phone number here'
          />
          <AddInfoButton onClick={onClick} style={addSingleFieldStyles}>
            <IcAddCircle color={colorValues.guajirogreen} />
            Add Chat Participant's Name
          </AddInfoButton>
        </Container>
      );
    case ParticipantInfoFieldState.noName:
      //an existing conversation without name, with phone (phone cannot be edited)
      return (
        <Container>
          <Input
            disabled
            value={normalizePhoneForSMSDisplay(phone.value)}
            onChange={() => {}}
          />
          <AddInfoButton onClick={onClick} style={addSingleFieldStyles}>
            <IcAddCircle color={colorValues.guajirogreen} />
            Add Chat Participant's Name
          </AddInfoButton>
        </Container>
      );
    case ParticipantInfoFieldState.noPhone:
      // any conversation without phone, with name.
      return (
        <Container>
          <Input
            onChange={handleNameChange}
            value={name}
            placeholder='Type name here'
          />
          <AddInfoButton onClick={onClick} style={addSingleFieldStyles}>
            <IcAddCircle color={colorValues.guajirogreen} />
            Add Chat Participant's Phone
          </AddInfoButton>
        </Container>
      );
    default:
      return (
        <AddInfoButton onClick={onClick}>
          <IcAddCircle color={colorValues.guajirogreen} />
          Add Chat Participant's Info
        </AddInfoButton>
      );
  }
};

export default InfoButtonAndFields;
