import React from 'react';
import styled from '@emotion/styled';

//types
import { Conversation } from '../../../../../../../generated/graphql';
import {
  UpdateConversationInput,
  MarkConversationAsReadInput,
} from '../../hooks/useConversationOperations';

//helpers
import { normalizePhoneForSMSDisplay } from '../../../../../../../helpers';

// components
import { Card, Header } from '@betterpt/better-components';
import NewMessageSection from './NewMessageSection';
import MessagesContainer from './MessagesContainer';
import ConversationCTASection from './ConversationCTASection';
import ChatParticipantInfo from './ChatParticipantInfo';

//hooks
import { useParams } from 'react-router-dom';
import {
  useConversationQuery,
  useFacilityQuery,
} from '../../../../../../../generated/graphql';
import { AddMessageToConversationInput } from '../../hooks/useConversationOperations';
import useTextFieldState from '../../../../../../../hooks/useTextFieldState';
import useHandleError from '../../../../../../../hooks/useHandleError';

// styles
const CardStyle = {
  width: '100%',
  height: '75vh',
  minHeight: 592,
  minWidth: 675,
  maxWidth: 1030,
  display: 'flex',
  flexDirection: 'column' as 'column',
};

const ConversationBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const P = styled.p`
  font-size: 16px;
  font-weight: bold;
`;

const patientInfoStyle = {
  height: 67,
  minHeight: 67,
};

interface Props {
  facilityId: string;
  addMessageToConversation: (
    props: AddMessageToConversationInput
  ) => Promise<any>;
  updateConversation: (props: UpdateConversationInput) => Promise<any>;
  markConversationAsRead: (props: MarkConversationAsReadInput) => Promise<any>;
}

const ConversationDetail = ({
  facilityId,
  addMessageToConversation,
  updateConversation,
  markConversationAsRead,
}: Props) => {
  const { conversationId } = useParams<{ conversationId: string }>();
  const handleError = useHandleError();

  const name = useTextFieldState();
  const message = useTextFieldState();
  const [sendingMessage, updateSendingMessage] = React.useState(false);
  const [markingIdAsRead, updateMarkingIdAsRead] = React.useState<
    string | undefined
  >();

  const facilityQuery = useFacilityQuery({
    variables: { id: facilityId },
    skip: !facilityId,
    fetchPolicy: 'cache-first',
  });

  const conversationQuery = useConversationQuery({
    variables: { id: conversationId },
    pollInterval: 5000,
  });

  React.useEffect(() => {
    message.updateValue('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId]);

  React.useEffect(() => {
    name.updateValue(conversationQuery.data?.conversation?.patientName ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationQuery.data]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    name.updateValue(event.target.value);
  };

  const handleSend = async () => {
    updateSendingMessage(true);
    try {
      await addMessageToConversation({
        conversationId,
        text: message,
      });
      message.updateValue('');
      updateSendingMessage(false);
    } catch (err) {
      updateSendingMessage(false);
      handleError(err);
    }
  };

  const handleMarkAsRead = async ({ messageId }: { messageId: string }) => {
    updateMarkingIdAsRead(messageId);
    try {
      await markConversationAsRead({
        conversationId,
      });
      updateMarkingIdAsRead(undefined);
    } catch (err) {
      updateMarkingIdAsRead(undefined);
      handleError(err);
    }
  };

  const conversation = conversationQuery.data?.conversation;
  const facilityPhone = facilityQuery.loading
    ? ''
    : ` ${normalizePhoneForSMSDisplay(
        facilityQuery.data?.clinic?.smsConfig?.smsPhoneNumber
      )}`;

  return (
    <Card style={CardStyle}>
      <Header underline style={{ alignItems: 'flex-start' }}>
        <P>
          From: {facilityQuery.data?.clinic?.clinicName ?? ''}
          {facilityPhone}
        </P>
      </Header>
      <Header underline style={patientInfoStyle}>
        <ChatParticipantInfo
          loading={conversationQuery.loading}
          conversationId={conversationId}
          name={name.value}
          phone={{ value: conversation?.patientPhone ?? '' }}
          handleNameChange={handleNameChange}
          updateConversation={updateConversation}
        />
      </Header>
      <ConversationBody>
        <MessagesContainer
          conversation={conversation as Conversation}
          handleMarkAsRead={handleMarkAsRead}
          markingIdAsRead={markingIdAsRead}
        />
        <NewMessageSection
          value={message.value}
          onChange={(e) => message.updateValue(e.target.value)}
        />
        <ConversationCTASection
          handleSend={handleSend}
          sendingMessage={sendingMessage}
        />
      </ConversationBody>
    </Card>
  );
};

export default ConversationDetail;
