import React from 'react';

import styled from '@emotion/styled';

import { colorValues } from '@betterpt/better-components';

const NewMessage = styled.div`
  border-top: 2px solid ${colorValues.guajirogreen};
  height: 82px;
  background-color: ${colorValues.emptiness};
  padding-top: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;

const TextArea = styled.textarea`
  resize: none;
  width: 100%;
  height: 75%;
  margin: 0 32px;
  border: none;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  font-family: Roboto, sans-serif;
  &:focus {
    outline: none;
  }
`;
const NewMessageSection = (
  props: React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >
) => {
  return (
    <NewMessage>
      <TextArea
        placeholder='Write your message here. Please note, to protect PHI we do not allow image transfers'
        {...props}
      />
    </NewMessage>
  );
};

export default NewMessageSection;
