import React from 'react';

//types
import { Message } from '../../../../../../../generated/graphql';

//helpers
import { format } from 'date-fns-tz';

//hooks
import { useEmployeeNameQuery } from '../../../../../../../generated/graphql';

//components
import Signature from './Signature';

//styles
import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 23px 0 0 0;
  align-items: flex-end;
`;

const MessageText = styled.div<{ showExtraRoom: boolean }>`
  background-color: ${colorValues.ibizateal};
  width: 514px;
  margin-right: 35px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding-bottom: ${({ showExtraRoom }) => (showExtraRoom ? '20px' : '8px')};
  position: relative;
  p {
    color: ${colorValues.emptiness};
    margin: 14px 15px 5px 15px;
  }
`;

const ClinicMessageBubble = ({
  message,
  renderSignature,
  employeeId,
}: {
  message: Message;
  renderSignature: boolean;
  employeeId?: string;
}) => {
  const employeeNameQuery = useEmployeeNameQuery({
    skip: !employeeId,
    fetchPolicy: 'cache-first',
    variables: {
      id: employeeId!,
    },
  });

  const employeeName =
    (
      (employeeNameQuery.data?.employee?.firstName || '') +
      ' ' +
      (employeeNameQuery.data?.employee?.lastName || '')
    ).trim() || '(No employee name on file)';
  const date = format(
    new Date(message.timestamp ?? new Date()),
    'M/d/yy h:mm aa'
  );
  const text = employeeNameQuery.loading ? '' : `${employeeName}, ${date}`;

  return (
    <Wrapper>
      {employeeNameQuery.loading ? null : (
        <MessageText showExtraRoom={renderSignature}>
          {message.text.split('\n').map((t, i) => (
            <p key={i}>{t}</p>
          ))}
          <Signature text={text} renderSignature={renderSignature} />
        </MessageText>
      )}
    </Wrapper>
  );
};

export default ClinicMessageBubble;
