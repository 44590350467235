import { loader } from 'graphql.macro';
import { TextFieldState } from '../../../../../../hooks/useTextFieldState';
import useRemoteConfigAliases from '../../../../../../hooks/useRemoteConfigAliases';
import { useHistory } from 'react-router-dom';
import {
  useAddMessageToConversationMutation,
  useCreateConversationMutation,
  useUpdateConversationMutation,
  useConversationsLazyQuery,
  useMarkMessagesAsReadMutation,
  Conversation,
} from '../../../../../../generated/graphql';
// import { MY_UNREAD_CONVERSATIONS } from '../../../../Notifications/components/NotificationsPanel';
const CONVERSATION_QUERY = loader(
  '../../../../../../gql/smsMessaging/Conversation.graphql'
);

export interface StartConversationInput {
  clinicId: string;
  patientPhone: string;
  patientName?: string;
  message?: TextFieldState;
}

export interface UpdateConversationInput {
  conversationId?: string;
  patientName: string;
}

export interface AddMessageToConversationInput {
  conversationId: string;
  text: TextFieldState;
  newConversation?: boolean;
}

export interface MarkConversationAsReadInput {
  conversationId: string;
}

const conversationError = new Error(
  'Something went wrong accessing this conversation! Please refresh and try again.'
);

const useConversationOperations = () => {
  const { push } = useHistory();
  const { facilitiesSingularAlias } = useRemoteConfigAliases();
  const [createNewConversation] = useCreateConversationMutation();
  const [updateExistingConversation] = useUpdateConversationMutation();
  const [addMessage] = useAddMessageToConversationMutation();
  const [markAsRead] = useMarkMessagesAsReadMutation();
  const [
    callExistingConversations,
    existingConversationsQuery,
  ] = useConversationsLazyQuery();

  const addMessageToConversation = async ({
    conversationId,
    text,
    newConversation,
  }: AddMessageToConversationInput) => {
    if (!text.value?.trim().length) {
      throw new Error('Please add content to your message');
    }

    await addMessage({
      variables: {
        input: {
          conversationId,
          text: text.value,
        },
      },
      update(cache, { data }) {
        const newMessage = data?.addMessageToConversation;
        const existingConversationData = cache.readQuery<{
          conversation: Conversation;
        }>({
          query: CONVERSATION_QUERY,
          variables: { id: conversationId },
        });
        if (!newConversation) {
          if (!existingConversationData?.conversation) {
            throw conversationError;
          }
          if (newMessage) {
            cache.modify({
              id: cache.identify(existingConversationData.conversation),
              fields: {
                messages() {
                  return [newMessage, ...(existingConversationData?.conversation.messages ?? [])];
                },
                mostRecentMessage() {
                  return newMessage;
                },
              },
            });
          }
        }
      },
    });
  };

  const createConversation = async ({
    clinicId,
    patientPhone,
    patientName,
    message,
  }: StartConversationInput) => {
    if (!patientPhone) {
      throw new Error('Please add phone to start conversation');
    }
    if (!message?.value?.trim().length) {
      throw new Error('Please add content to your message');
    }
    if (message?.value.length >= 1600) {
      throw new Error('Messages must be less than 1600 characters');
    }
    if (!clinicId) {
      throw new Error(
        `Something went wrong accessing this ${facilitiesSingularAlias.toLowerCase()}, please refresh and try again`
      );
    }

    await callExistingConversations({
      variables: {
        clinicId,
      },
    });
    const existingConversations =
      existingConversationsQuery.data?.conversations ?? [];
    //check for an existing conversation with the target phone number before creating a whole new one
    for (let i = 0; i < existingConversations.length; i++) {
      if (existingConversations?.[i]?.patientPhone === `+1${patientPhone}`) {
        const conversationId = existingConversations?.[i]?.id;
        if (conversationId) {
          await addMessageToConversation({ conversationId, text: message });
        }
        push(
          `/facilities/${clinicId}/sms-messages/${conversationId}/conversation`
        );
        throw new Error('A conversation with this participant already exists');
      }
    }

    const response = await createNewConversation({
      variables: {
        input: {
          clinicId,
          patientPhone: `+1${patientPhone}`,
          patientName,
        },
      },
      update(cache, { data }) {
        const newConversation = data?.createConversation;
        if (!newConversation) {
          throw conversationError;
        }
        cache.modify({
          fields: {
            conversations() {
              return [newConversation, ...existingConversations];
            },
          },
        });
      },
    });

    const conversationId = response?.data?.createConversation?.id;

    if (message && conversationId) {
      await addMessageToConversation({
        conversationId,
        text: message,
        newConversation: true,
      });
    }
    push(`/facilities/${clinicId}/sms-messages/${conversationId}/conversation`);
  };

  const updateConversation = async ({
    conversationId,
    patientName,
  }: UpdateConversationInput) => {
    if (!conversationId) {
      throw conversationError;
    }
    if (!patientName.length) {
      throw new Error(
        'Please enter at least one valid name for this participant.'
      );
    }

    await updateExistingConversation({
      variables: {
        input: {
          id: conversationId,
          patientName,
        },
      },
    });
  };

  const markConversationAsRead = async ({
    conversationId,
  }: MarkConversationAsReadInput) => {
    if (!conversationId) {
      throw conversationError;
    }
    await markAsRead({
      variables: {
        conversationId,
      },
      // refetchQueries: [{ query: MY_UNREAD_CONVERSATIONS }],
    });
  };

  return {
    commands: {
      createConversation,
      updateConversation,
      addMessageToConversation,
      markConversationAsRead,
    },
  };
};

export default useConversationOperations;
