import React from 'react';

//types
import { Message } from '../../../../../../../generated/graphql';

//helpers
import { format } from 'date-fns-tz';

//hooks
import { useEmployeeNameQuery } from '../../../../../../../generated/graphql';

//components
import { Radio } from '@betterpt/better-components';
import { SignatureText } from './Signature';

//assets
import { IcDoubleCheck } from '@betterpt/better-icons';

//styles
import styled from '@emotion/styled';
import { colorValues } from '@betterpt/better-components';

const MarkReadButton = styled.button`
  border: none;
  width: 208px;
  height: 36px;
  border-radius: 23.5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${colorValues.emptiness};
  box-shadow: 0 0 4px 0 rgba(51, 51, 51, 0.47),
    0 2px 2px 0 rgba(51, 51, 51, 0.24);
  position: absolute;
  bottom: -22px;
  left: -2px;
  &:hover,
  &:focus {
    background-color: ${colorValues.dust};
  }
  p {
    font-weight: bold;
    color: ${colorValues.charcoalgray};
    margin: 0;
  }
  .MuiRadio-root {
    padding: 0 2px 0 7px !important;
    svg {
      color: ${colorValues.charcoalgray} !important;
      margin: 3px 3px 0 0;
    }
  }
`;

const readSignatureStyle = {
  bottom: '-30px',
  right: 'auto',
  left: 0,
  display: 'flex',
  alignItems: 'center',
};

interface Props {
  message: Message;
  showUnread: boolean;
  readButNotRepliedTo: boolean;
  handleMarkAsRead: ({ messageId }: { messageId: string }) => Promise<void>;
  markingIdAsRead?: string;
}

const ReadOrUnreadMarker = ({
  message,
  showUnread,
  readButNotRepliedTo,
  handleMarkAsRead,
  markingIdAsRead,
}: Props) => {
  const messageId = message?.id;
  const employeeId = message?.firstViewedByEmployeeId;

  const employeeNameQuery = useEmployeeNameQuery({
    skip: !employeeId,
    fetchPolicy: 'cache-first',
    variables: {
      id: employeeId!,
    },
  });

  const employeeName =
    (
      (employeeNameQuery.data?.employee?.firstName || '') +
      ' ' +
      (employeeNameQuery.data?.employee?.lastName || '')
    ).trim() || '(No employee name on file)';
  const date = format(
    new Date(message.firstViewedAt ?? new Date()),
    "MM/dd/yy 'at' h:mm aa"
  );

  if (showUnread || markingIdAsRead === messageId) {
    return (
      <MarkReadButton onClick={() => handleMarkAsRead({ messageId })}>
        <Radio
          checked={markingIdAsRead === messageId}
          onChange={() => handleMarkAsRead({ messageId })}
          data-cy='mark-as-read-radio'
        />
        <p>mark conversation read</p>
      </MarkReadButton>
    );
  } else if (readButNotRepliedTo) {
    return (
      <SignatureText style={readSignatureStyle}>
        <IcDoubleCheck /> Read by {employeeName} on {date}
      </SignatureText>
    );
  } else return null;
};

export default ReadOrUnreadMarker;
