import React from 'react';
import styled from '@emotion/styled';

import { Message, Conversation } from '../../../../../../../generated/graphql';

//components
import ClinicMessageBubble from './ClinicMessageBubble';
import PatientMessageBubble from './PatientMessageBubble';

const MessagesBox = styled.div<{ showExtraRoom: boolean }>`
  height: ${({ showExtraRoom }) =>
    `calc(75vh - ${showExtraRoom ? '387' : '362'}px)`};
  min-height: 212px;
  overflow: auto;
  flex-direction: column-reverse;
  display: flex;
  padding-bottom: ${({ showExtraRoom }) => (showExtraRoom ? 50 : 25)}px;
`;

interface Props {
  conversation: Conversation;
  handleMarkAsRead: ({ messageId }: { messageId: string }) => Promise<void>;
  markingIdAsRead?: string;
}
const MessagesContainer = ({
  conversation,
  handleMarkAsRead,
  markingIdAsRead,
}: Props) => {
  const messages: Message[] = (conversation?.messages as Message[]) || [];
  const renderSignature = (index: number, message: Message) =>
    messages[index - 1]?.employeeId !== message?.employeeId;

  const isUnread = (message: Message) =>
    !message?.firstViewedAt && !message?.firstViewedByEmployeeId;
  const isMostRecentUnreadPatient = (index: number, message: Message) =>
    index === 0 && !message?.employeeId && isUnread(message);
  const patientReadButNotRepliedTo = (index: number, message: Message) =>
    index === 0 &&
    !message?.employeeId &&
    !!message?.firstViewedAt &&
    !!message?.firstViewedByEmployeeId;

  const mostRecentMessage = conversation?.mostRecentMessage as Message;
  const giveSpaceForUnread = isMostRecentUnreadPatient(0, mostRecentMessage);
  const giveSpaceForRead = patientReadButNotRepliedTo(0, mostRecentMessage);
  const showExtraRoom = giveSpaceForUnread || giveSpaceForRead;

  return (
    <MessagesBox showExtraRoom={showExtraRoom}>
      {messages.map((message, index) => (
        <div key={message.id}>
          {message.employeeId ? (
            <ClinicMessageBubble
              message={message}
              employeeId={message.employeeId}
              renderSignature={renderSignature(index, message)}
            />
          ) : (
            <PatientMessageBubble
              message={message}
              renderSignature={renderSignature(index, message)}
              showUnread={isMostRecentUnreadPatient(index, message)}
              readButNotRepliedTo={patientReadButNotRepliedTo(index, message)}
              handleMarkAsRead={handleMarkAsRead}
              markingIdAsRead={markingIdAsRead}
            />
          )}
        </div>
      ))}
    </MessagesBox>
  );
};

export default MessagesContainer;
