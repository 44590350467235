import * as React from 'react';

//helpers
import { Roles } from '../../../../helpers';
import { colorValues, darkenLightColor } from '@betterpt/better-components';

// hooks
import useDebounce from '../../../../hooks/useDebounce';
import useRemoteConfigAliases from '../../../../hooks/useRemoteConfigAliases';
import { useCompanyFacilitiesQuery, useMeQuery, useTotalFacilitiesQuery } from '../../../../generated/graphql';
import { useMediaQuery } from '@material-ui/core';
import useSnackbar from '../../../../hooks/useSnackbar';

// components
import { useHistory } from 'react-router-dom';
import { Card, Table } from '@betterpt/better-components';
import { checkPermissions } from '../../../Can';
import NoFacilitiesFound from './NoFacilitiesFound';
import ThemedTextField from '../../../Shared/ThemedTextField';
import FacilityActionButton from './FacilityActionButton';
import IntegrationColumn from './IntegrationColumn';
import LocationColumn from './LocationColumn';

//assets
import { IcBusiness, IcSuccessCircle } from '@betterpt/better-icons';

//style
import { Page, Header } from '../Facilities.style';
import { TableStyleWrapper, TitleAndSearchSection } from './FacilitiesListView.style';
import SubscriptionColumn from './SubscriptionColumn';

const FacilitiesListView = () => {
  // hooks
  const snackbar = useSnackbar();
  const history = useHistory();
  const meQuery = useMeQuery();
  const smallScreen = useMediaQuery('(max-width: 1480px)', { noSsr: true });
  const { facilitiesSingularAlias, facilitiesPluralAlias, primaryColor } = useRemoteConfigAliases();

  const pageSize = 12;

  const [offset, setOffset] = React.useState(0);
  const [selectedClinicId, updateSelectedClinicId] = React.useState<string | null>(null);
  const [searchText, setSearchText] = React.useState('');
  const debouncedSearchText = useDebounce(searchText, 500);

  // apollo
  const queryFacilities = useCompanyFacilitiesQuery({
    skip: !meQuery?.data?.me?.company?.id,
    variables: {
      id: meQuery?.data?.me?.company?.id as string,
      input: { limit: pageSize, offset, query: debouncedSearchText },
    },
    fetchPolicy: 'cache-and-network',
  });

  const totalFacilities = useTotalFacilitiesQuery({
    variables: {
      id: meQuery?.data?.me?.company?.id as string,
    },
    fetchPolicy: 'cache-and-network',
  });

  const queryError = queryFacilities.error || totalFacilities.error;

  React.useEffect(() => {
    if (queryError?.message) {
      snackbar?.useGenericErrorMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryError]);

  const facilities = queryFacilities?.data?.company?.clinics?.result;
  const myFacilities = meQuery?.data?.me?.clinics;
  const companyName = meQuery.data?.me?.company?.companyName;

  React.useEffect(() => {
    if (debouncedSearchText) {
      setOffset(0);
    }
  }, [debouncedSearchText]);

  const tableColumns = [
    {
      field: 'facilityName',
      title: `${smallScreen ? '' : facilitiesSingularAlias.toUpperCase()} NAME`,
    },
    {
      field: 'id',
      title: `${smallScreen ? '' : facilitiesSingularAlias.toUpperCase()} ID`,
    },
    { field: 'location', title: 'LOCATION' },
    {
      field: 'inPerson',
      title: 'IN-PERSON',
    },
    {
      field: 'subscription',
      title: 'INTEGRATION',
      cellStyle: { minWidth: 120 },
    },
    {
      field: 'myFacility',
      title: `IS MY ${facilitiesSingularAlias.toUpperCase()}`,
    },
  ];

  const rowData =
    facilities?.map((clinic) => ({
      facilityName: clinic?.clinicName ?? '-',
      id: clinic?.id ?? '-',
      location: <LocationColumn clinic={clinic} />,
      telehealth: <SubscriptionColumn clinic={clinic} isTelehealth />,
      inPerson: <SubscriptionColumn clinic={clinic} />,
      subscription: <IntegrationColumn clinic={clinic} />,
      myFacility: myFacilities?.map((myClinic, i) => {
        if (myClinic?.id === clinic?.id) {
          return (
            <div
              key={`my-facility-${i}`}
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <IcSuccessCircle color={colorValues.guajirogreen} key={myClinic?.id} />
            </div>
          );
        } else return '';
      }),
    })) ?? [];

  // values
  const tableOptions = {
    minBodyHeight: 690,
    overflow: 'visible' as 'visible',
    pagination: {
      backToFirstPage: offset === 0,
      totalCount: queryFacilities?.data?.company?.clinics?.pager?.total || 0,
      primaryColor: darkenLightColor(primaryColor),
      pagesize: pageSize,
      onChangePage: (pageSize: number, idx: number) => {
        setOffset(idx * pageSize);
        window.scrollTo(0, 0);
      },
    },
    searchNotFoundText: 'Facility Not Found',
    searchNotFoundComponent: <NoFacilitiesFound isNoResultsPage resetFilters={() => setSearchText('')} />,
  };

  if ((!totalFacilities.data || totalFacilities.data?.company?.clinics?.pager?.total === 0) && !totalFacilities.loading) {
    return <NoFacilitiesFound />;
  }

  return (
    <Page style={{ width: '90%' }}>
      <Header>
        <h1 className="Hero">
          {facilitiesPluralAlias} at {companyName}
        </h1>
        <TitleAndSearchSection>
          <ThemedTextField
            style={{ marginBottom: '-7px', marginRight: 10 }}
            InputProps={{
              startAdornment: <IcBusiness color={colorValues.cityblock} />,
              style: { width: 207 },
            }}
            value={searchText}
            placeholder={`Enter ${facilitiesSingularAlias.toLowerCase()} name`}
            onChange={(e) => {
              setOffset(0);
              setSearchText(e.currentTarget.value);
            }}
          />
        </TitleAndSearchSection>
      </Header>
      <Card style={{ position: 'relative', width: '100%' }} fullWidthAtBreakpoint={1400}>
        <TableStyleWrapper>
          <Table
            onRowClick={(_e, rowData) => history.push(`/facilities/${rowData?.id}`)}
            columns={tableColumns}
            data={rowData}
            isLoading={queryFacilities?.loading}
            isSearching={!!searchText}
            options={tableOptions}
          />
        </TableStyleWrapper>
      </Card>
    </Page>
  );
};

export default FacilitiesListView;
